import { chakra, Flex, FlexProps } from "@chakra-ui/react";
import { ReactComponent as EqgLogoWhite } from "../../assets/logo-eqg-white.svg";
import { steps } from "./StepperDesktop";

export interface StepperMobileProps extends FlexProps {
  active: number;
  title: string;
}

export function StepperMobile({ active, title }: StepperMobileProps) {
  return (
    <Flex
      alignItems="center"
      bg="transparent linear-gradient(90deg, #00A1D0 0%, #00B3D5 33%, #00D2C5 67%, #16E0B0 100%) 0% 0% no-repeat padding-box;"
      h="84px"
      justifyContent="center"
      padding="18px 24px"
    >
      <Flex
        alignItems="center"
        h="100%"
        justifyContent="space-between"
        w="100%"
        maxW="306px"
      >
        <Flex flexDir="column">
          <chakra.h6
            color="white"
            fontFamily="primary"
            lineHeight="1.5rem"
            fontSize="1rem"
            fontWeight="semibold"
            textShadow="0px 1px 4px #00253D29"
          >
            Passo {steps[active - 1]?.num} de {steps.length}
          </chakra.h6>
          <chakra.h5
            color="white"
            fontFamily="primary"
            fontSize="20px"
            fontWeight="bold"
            lineHeight="28px"
          >
            {title}
          </chakra.h5>
        </Flex>
        <EqgLogoWhite
          style={{
            filter: "drop-shadow(0px 2px 4px #7090B059)",
            transform: "scale(1.3)",
            width: "60px",
          }}
        />
      </Flex>
    </Flex>
  );
}
