

import { BannerLayout, Contact } from "../../components";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { chakra, Flex } from "@chakra-ui/react";
import { DefaultErrorImg } from "../../assets";
import { useIsMobile } from "../../utils";

export function DefaultError() {

  const isMobile = useIsMobile();

  // const handleNextClick = useCallback(() => {
  //   history.push("/simular/" + data.encodeData);
  // }, [data, history]);

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        padding: "0px 24px",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo={true}
    >
      <Flex
        alignItems="center"
        flexDir="column"
        h="100%"
        justifyContent="center"
        maxW="306px"
        w="100%"
      >
        <DefaultErrorImg style={{ width: "90px" }} />

        <Spacer h="16px" />

        <chakra.h1
          color="primary.500"
          fontSize="1.5rem"
          textAlign="center"
          textStyle="title"
          lineHeight="1.95rem"
          letterSpacing="0px"
        >
          O sistema da Caixa apresentou uma instabilidade
        </chakra.h1>

        <Spacer h="12px" />

        <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
          Um(a) de nossos(as) Consultores(as) entrará em contato com você para dar
          continuidade ao atendimento através do WhatsApp ou por ligação com o
          DDD 19.
        </chakra.p>

        <Spacer h="24px" />

        <Contact />
      </Flex>
    </BannerLayout>
  );
}
