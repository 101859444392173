import { BannerLayout } from "./banner";
import { Carousel, TCarouselItem } from "./carousel";
import { chakra, Flex } from "@chakra-ui/react";
import { SecureEnvMobile } from "./SecureEnvMobile";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { useIsMobile } from "../utils";
export interface TutorialProps {
  backUrl: string;
  items: TCarouselItem[];
  title: string;
}

export function Tutorial({ backUrl, items, title }: TutorialProps) {
  const isMobile = useIsMobile();

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      customTitle={title}
    >
      <Flex flexDir="column" maxW="306px" w="100%">
        {!isMobile && (
          <>
            <chakra.h1
              color="primary.500"
              fontFamily="primary"
              fontSize="1.125rem"
              fontWeight="bold"
              letterSpacing="0.0262rem"
              lineHeight="1.4625rem"
              margin="0px 10px"
              textAlign="center"
            >
              {title}
            </chakra.h1>
            <Spacer h="16px" />
          </>
        )}
        <Carousel backUrl={backUrl} items={items} />
        {isMobile && (
          <>
            <Spacer h="20px" />
            <SecureEnvMobile />
          </>
        )}
      </Flex>
    </BannerLayout>
  );
}
