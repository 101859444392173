import { useCallback, useState } from "react";

import { Button, Spacer } from "@eqg/chakra-lib.components.all";
import { chakra, Flex } from "@chakra-ui/react";
import { Session } from "../../storage";
import { useData } from "../../stores";
import { useHistory } from "react-router";
import { useIsMobile } from "../../utils";

import {
  BannerLayout,
  CancelSimulationModal,
  SecureEnvMobile,
  Summary,
} from "../../components";

export function ResumeStep() {
  const isMobile = useIsMobile();
  const data = useData();

  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const history = useHistory();

  const handleCloseCancel = useCallback(() => {
    setIsCancelOpen(false);
  }, [setIsCancelOpen]);

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        paddingTop: isMobile ? "24px" : "60px",
      }}
    >
      <Flex flexDir="column" maxW="306px" w="100%">
        <chakra.h4
          color="primary.500"
          fontSize="1.25rem"
          fontWeight="bold"
          letterSpacing="0.025rem"
          textStyle="h4"
          lineHeight="26px"
        >
          Olá, {data.getFirstName()}! Você possui uma simulação em andamento
        </chakra.h4>

        <Spacer h="12px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Confira o resumo abaixo. Vamos continuar?
        </chakra.p>

        <Spacer h="20px" />

        <Summary />

        <CancelSimulationModal
          isOpen={isCancelOpen}
          onClose={handleCloseCancel}
        />

        <Spacer h="48px" />

        <Button
          borderRadius="24px"
          w="100%"
          onClick={() => {
            if (data.bank) {
              Session.setCurrentStep(6, 6);
              history.push("/resumo");
            } else if (data.cep) {
              Session.setCurrentStep(5, 5);
              history.push("/dadosbancarios");
            } else if (data.birthdate) {
              Session.setCurrentStep(4, 4);
              history.push("/endereco");
            } else {
              Session.setCurrentStep(3, 3);
              history.push("/dadospessoais");
            }
          }}
        >
          Continuar
        </Button>

        {isMobile && (
          <>
            <Spacer h="20px" />
            <SecureEnvMobile />
          </>
        )}
      </Flex>
    </BannerLayout>
  );
}
