import { BannerLayout, Contact } from "../../components";
import { Button, Spacer } from "@eqg/chakra-lib.all";
import { chakra, Flex } from "@chakra-ui/react";
import { useData } from "../../stores";
import { useIsMobile } from "../../utils";

export function NoBalance() {
  const isMobile = useIsMobile();
  const data = useData();

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        padding: "0px 24px",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo={true}
    >
      <Flex
        alignItems="center"
        flexDir="column"
        h="100%"
        justifyContent="flex-start"
        maxW="306px"
        w="100%"
      >
        <chakra.h1
          color="primary.500"
          fontSize="1.25rem"
          lineSpacing="1.625rem"
          letterSpacing="0px"
          textStyle="h5"
        >
          Olá, {data.getFirstName()}. Parece que a Antecipação do FGTS não está
          disponível para você.
        </chakra.h1>

        <Spacer h="12px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Mas não desanime! Que tal aproveitar outras oportunidades da Eu quero
          Grana exclusivas para você?
        </chakra.p>

        <Spacer h="24px" />

        <Button
          borderRadius="24px"
          onClick={() => {
            window.location.href = "https://euquerograna.com.br/conta-digital/";
          }}
          w="100%"
        >
          Ver oportunidades
        </Button>

        <Spacer h="32px" />

        <Contact />
      </Flex>
    </BannerLayout>
  );
}
