import { BannerLayout, Contact } from "../../components";
import { chakra, Flex } from "@chakra-ui/react";
import { Spacer } from "@eqg/chakra-lib.all";
import successAnimation from "../../assets/success-animation.gif";
import { useIsMobile } from "../../utils";

export function Success() {
  const isMobile = useIsMobile();

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        padding: "0px 24px",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo={true}
    >
      <Flex
        alignItems="center"
        flexDir="column"
        h="100%"
        justifyContent="center"
        maxW="306px"
        w="100%"
      >
        <img alt="Sucesso" src={successAnimation} />

        <chakra.h1
          color="primary.500"
          fontSize="1.5rem"
          textAlign="center"
          textStyle="title"
        >
          Agradecemos sua colaboração!
        </chakra.h1>

        <Spacer h="54px" />

        <Contact />
      </Flex>
    </BannerLayout>
  );
}
