import { createBreakpoints } from "@chakra-ui/theme-tools";
import { theme as eqgTheme } from "@eqg/chakra-lib.all";

const breakpoints = createBreakpoints({
  base: "0px",
  xs: "320px",
  sm: "576px",
  md: "769px",
  lg: "992px",
  xl: "1366px",
  "2xl": "2000px",
});

export const theme = {
  ...eqgTheme,
  breakpoints,
  shadows: {
    outline: `0 0 3px  #412E6E`,
  },
  colors: {
    ...eqgTheme.colors,
    primary: {
      50: "#efe6f7",
      100: "#412E6E0A",
      200: "#CEB8F0",
      300: "#A489D3",
      400: "#765EA8",
      500: "#412E6E",
      600: "#31215E",
      700: "#24174F",
      800: "#180E3F",
      900: "#100834",
    },
  },
  fonts: {
    primary: "'Montserrat', 'Times New Roman'",
    secondary: "'Open Sans', sans-serif",
  },
  textStyles: {
    ...eqgTheme.textStyles,
    label: {
      fontFamily: "primary",
      fontSize: "1rem",
      fontWeight: "bold",
      letterSpacing: "sm",
      lineHeight: "1.5rem",
    },
    title: {
      fontFamily: "primary",
      fontSize: "2rem",
      fontWeight: "bold",
      lineHeight: "2.25rem",
    },
    smallTitle: {
      fontFamily: "primary",
      fontSize: "1.5rem",
      fontWeight: "bold",
      letterSpacing: "0.0362rem",
      lineHeight: "2.1rem",
    },
    subtitle: {
      fontFamily: "primary",
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
      fontWeight: "medium",
    },
    p: {
      fontFamily: "primary",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: "medium",
    },
    moneyText: {
      fontFamily: "primary",
      fontSize: "1.25rem",
      lineHeight: "1.5rem",
      fontWeight: "bold",
    },
    fieldHeadline: {
      fontFamily: "primary",
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: "1.5rem",
    },
    secure: {
      fontFamily: "primary",
      fontSize: "0.875rem",
      fontWeight: "semibold",
      lineHeight: "1.1875rem",
    },
  },
};
