import { useCallback, useMemo } from "react";

import { useIsMobile } from "../../utils";
import { BText, SecureEnvMobile, StepperLayout } from "..";
import { Button, Spacer } from "@eqg/chakra-lib.all";
import { FiInfo } from "react-icons/fi";
import { Session } from "../../storage";
import { TotalBox } from "./TotalBox";
import { SkipButton } from "../SkipButton";
import { useData } from "../../stores";
import { useHistory } from "react-router";

import {
  Box,
  chakra,
  Flex,
  Spacer as CSpacer,
  useTheme,
} from "@chakra-ui/react";

export function SimulationOnlyTotal() {
  const data = useData();
  const history = useHistory();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const tax = useMemo(() => {
    if (!data.monthlyTax) {
      return "Não encontrado";
    }

    return parseFloat(data.monthlyTax.toString()).toFixed(2) + "% ao mês";
  }, [data.monthlyTax]);

  const iof = useMemo(() => {
    if (!data.iof) {
      return "Não encontrado";
    }

    return parseFloat(data.iof.toString()).toFixed(2) + "%";
  }, [data.iof]);

  const handleSacarClick = useCallback(() => {
    Session.setCurrentStep(3, 3);
    history.push("/dadospessoais");
  }, [history]);

  return (
    <StepperLayout>
      <Flex
        bg="lightgray.200"
        flex={1}
        justifyContent="center"
        overflow="auto"
        padding="0px 24px"
      >
        <Flex
          flexDir="column"
          marginTop={{ base: "20px", md: "64px" }}
          maxW="306px"
          w="100%"
        >
          {!isMobile && (
            <>
              <chakra.h2 color="primary.500" textStyle="smallTitle">
                Simulação
              </chakra.h2>
              <Spacer h="0.5rem" />
            </>
          )}

          <chakra.p color="darkgray.500" textStyle="p">
            Olá, {data.getFirstName()}! Sua simulação foi realizada pelo{" "}
            <BText>Banco {data.getSimBank("full")}</BText>.
          </chakra.p>

          <Spacer h="16px" />

          <TotalBox />

          <Spacer h="20px" />

          <Flex>
            <chakra.p color="darkgray.400" textStyle="p">
              Taxa de juros
            </chakra.p>

            <Spacer w="10px" />

            <Box
              borderColor="lightgray.800"
              borderBottomWidth="2px"
              borderStyle="dotted"
              marginBottom="4px"
              flex={1}
            />

            <Spacer w="4px" />

            <chakra.p color="darkgray.700" fontWeight="bold" textStyle="p">
              {tax}
            </chakra.p>
          </Flex>

          <Spacer h="16px" />

          <Flex>
            <chakra.p color="darkgray.400" textStyle="p">
              IOF (imposto)
            </chakra.p>

            <Spacer w="10px" />

            <Box
              borderColor="lightgray.800"
              borderBottomWidth="2px"
              borderStyle="dotted"
              marginBottom="4px"
              flex={1}
            />

            <Spacer w="4px" />

            <chakra.p color="darkgray.700" fontWeight="bold" textStyle="p">
              {iof}
            </chakra.p>
          </Flex>

          <Spacer h="20px" />

          {/*{data.simResult?.map((result) => (
            <React.Fragment key={result.year}>
              <Flex>
                <chakra.p color="darkgray.400" textStyle="p">
                  {result.year}
                </chakra.p>

                <Spacer w="10px" />

                <Box
                  borderColor="lightgray.800"
                  borderBottomWidth="2px"
                  borderStyle="dotted"
                  marginBottom="4px"
                  flex={1}
                />

                <Spacer w="4px" />

                <chakra.p color="darkgray.700" fontWeight="bold" textStyle="p">
                  {" "}
                  {brlFormat.format(result.value)}
                </chakra.p>
              </Flex>

              <Spacer h="20px" />
            </React.Fragment>
          ))}*/}

          <Flex w="100%">
            <FiInfo color={theme.colors.darkgray[100]} fontSize="24px" />

            <Spacer w="8px" />

            <chakra.p color="darkgray.500" textStyle="p">
              Valores sujeitos a alterações.
            </chakra.p>
          </Flex>

          <Spacer h="44px" />

          <Button borderRadius="24px" h="44px" onClick={handleSacarClick}>
            Sacar valor
          </Button>

          {isMobile && (
            <>
              <Spacer h="16px" />
              <SecureEnvMobile />
            </>
          )}

          <SkipButton topMargin="24px" />

          <CSpacer h="32px" minH="32px" />
        </Flex>
      </Flex>
    </StepperLayout>
  );
}
