import { useCallback, useMemo } from "react";

import { Button, Spacer } from "@eqg/chakra-lib.all";
import { Session } from "../storage";
import { useData } from "../stores";
import { useHistory } from "react-router-dom";
import { useIsMobile } from "../utils";

export interface SkipButtonProps {
  topMargin?: string;
}

export function SkipButton({ topMargin }: SkipButtonProps) {
  const storeData = useData();
  const history = useHistory();
  const isMobile = useIsMobile();

  const isSkipable = useMemo(() => {
    if (storeData.account) {
      return true;
    }

    return false;
  }, [storeData.account]);

  const handleSkip = useCallback(() => {
    Session.setCurrentStep(6, 6);
    history.push("/resumo");
  }, [history]);

  return (
    <>
      {isSkipable ? (
        <>
          <Spacer h={topMargin || "4px"} />
          <Button onClick={handleSkip} variant="link">
            Pular para o resumo
          </Button>
          {isMobile && <Spacer h="24px" />}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
