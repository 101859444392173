import { Flex } from "@chakra-ui/layout";
import { Session } from "../../storage";
import { steps } from ".";
import { StepperDesktop } from "./StepperDesktop";
import { StepperMobile } from "./StepperMobile";
import { useBreakpointValue } from "@chakra-ui/media-query";

export interface StepperLayoutProps {
  children: React.ReactNode;
}

export function StepperLayout({ children }: StepperLayoutProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex flexDir={isMobile ? "column" : "row"} h="100%" w="100%">
      {isMobile ? (
        <StepperMobile
          active={Session.getActiveStep()}
          title={steps[Session.getActiveStep() - 1]?.shortName}
        />
      ) : (
        <StepperDesktop active={Session.getActiveStep()} />
      )}

      <Flex flex={1}>{children}</Flex>
    </Flex>
  );
}
