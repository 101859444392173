import { useCallback, useState } from "react";

import { chakra, Flex } from "@chakra-ui/react";
import { Session } from "../../storage";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { useData } from "../../stores";
import { useHistory } from "react-router";
import { useIsMobile } from "../../utils";
import { useSnackbar } from "notistack";

import {
  BannerLayout,
  CancelSimulationModal,
  FormActions,
  Summary,
} from "../../components";

export function SummaryStep() {
  const isMobile = useIsMobile();
  const history = useHistory();
  const data = useData();
  const { enqueueSnackbar } = useSnackbar();
  const phone = data.whatsapp
  .replace("(", "")
  .replace(")", "")
  .replace(" ", "")
  .replace("-", "");



  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleBack = useCallback(() => {
    Session.setActiveStep(5);
    history.push("/dadosbancarios");
  }, [history]);

  const handleNext = useCallback(async () => {
    setIsLoading(true);

    const result = await data.sendClient();

    setIsLoading(false);

    if (result.error) {
      enqueueSnackbar(
        "Ocorreu um erro ao tentar gravar os dados, tente novamente mais tarde",
        { variant: "error" }
      );
    } else {
      Session.setCurrentStep(109, 109);
      history.push("/feedback");

      //@ts-ignore
      window.dataLayer.push({
        event: "fgts-contrato-success",
        "valor-contrato": data.simResultTotal,
        "transaction-id": data.encodeData,
        email: data.email,
        phone_number: `+55${phone}`,
        "address.first_name": data.getFirstName(),
        "address.last_name": data.getLastName(),
        "address.street": data.address,
        "address.city": data.city,
        "address.region": data.state,
        "address.postal_code": data.cep,
        "address.country": "Br",
      });
    }
  }, [data, enqueueSnackbar, history, phone]);

  const handleOpenCancel = useCallback(() => {
    setIsCancelOpen(true);
  }, [setIsCancelOpen]);

  const handleCloseCancel = useCallback(() => {
    setIsCancelOpen(false);
  }, [setIsCancelOpen]);

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo
    >
      <Flex flexDir="column" maxW="306px" w="100%">
        <chakra.h4
          color="primary.500"
          fontSize="1.5rem"
          fontWeight="bold"
          lineHeight="1.95rem"
          letterSpacing="0.48px"
          textStyle="h4"
        >
          Confira o resumo da sua proposta
        </chakra.h4>

        <Spacer h="12px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Caso necessário, você pode editar suas informações.
        </chakra.p>

        <Spacer h="20px" />

        <Summary />

        <CancelSimulationModal
          isOpen={isCancelOpen}
          onClose={handleCloseCancel}
        />

        <Spacer h="48px" />

        <FormActions
          backLabel="Voltar"
          cancelLabel="Cancelar simulação"
          isLoading={isLoading}
          nextLabel="Finalizar"
          noForm
          onBack={handleBack}
          onCancel={handleOpenCancel}
          onNext={handleNext}
        />
      </Flex>
    </BannerLayout>
  );
}
