import { chakra, Flex } from "@chakra-ui/react";
import { cnhImg, rgImg } from "../assets";
import { Control } from "react-hook-form";
import { TDadosPessoaisWithDocumentSchema } from "../schemas";
import { useWatch } from "react-hook-form";

import {
  InputControl,
  RadioboxGroup,
  Spacer,
} from "@eqg/chakra-lib.components.all";

export interface RgCnhFieldProps {
  control: Control<TDadosPessoaisWithDocumentSchema, object>;
  error?: string;
}

export function RgCnhField({ control, error }: RgCnhFieldProps) {
  const documentType = useWatch({ control: control, name: "documentType" });

  return (
    <>
      <InputControl error={error}>
        <RadioboxGroup
          control={control}
          wGap="16px"
          name="documentType"
          overflow="auto"
          options={[
            {
              value: "RG",
              label: (
                <chakra.h6
                  fontWeight={documentType === "RG" ? "bold" : "medium"}
                  textStyle="p"
                >
                  RG
                </chakra.h6>
              ),
            },
            {
              value: "CNH",
              label: (
                <chakra.h6
                  fontWeight={documentType === "CNH" ? "bold" : "medium"}
                  textStyle="p"
                >
                  CNH
                </chakra.h6>
              ),
            },
          ]}
          radioboxProps={{
            h: "48px",
            justifyContent: "space-between",
            w: "50%",
            padding: "16px",
          }}
          w="100%"
        />
      </InputControl>

      <Spacer h="16px" />

      <Flex minH="200px" w="100%">
        <chakra.img
          alt="como ver o número do rg"
          flex={1}
          src={documentType === "CNH" ? cnhImg : rgImg}
        />
      </Flex>
    </>
  );
}
