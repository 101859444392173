import { Footer } from "./Footer";
import homeImg from "../../assets/home-img.png";
import { ReactComponent as EqgLogoWhite } from "../../assets/logo-eqg-white.svg";
import { Safe } from "./Safe";

import {
  chakra,
  Flex,
  FlexProps,
  Spacer,
  useMediaQuery,
} from "@chakra-ui/react";

interface BannerDesktopProps extends FlexProps {}

export function BannerDesktop(props: BannerDesktopProps) {
  const [isTablet, isSmallerThan700, isSmallerThan600] = useMediaQuery([
    "(min-height: 1024px) and (max-width: 1366px)",
    "(max-height: 700px) or (max-width: 992px),",
    "(max-height: 620px)",
  ]);

  return (
    <Flex
      alignItems="center"
      bg="transparent linear-gradient(90deg, #00A1D0 0%, #00B3D5 33%, #00D2C5 67%, #16E0B0 100%) 0% 0% no-repeat padding-box;"
      direction="column"
      h="100%"
      justifyContent="flex-start"
      maxH={{ base: "264px", md: "100%" }}
      minH={{ base: "200px", md: "100%" }}
      overflowX="hidden"
      overflowY={isSmallerThan600 ? "auto" : "hidden"}
      padding="24px"
      w={{ base: "100%", md: "40%" }}
      {...props}
    >
      <Flex
        alignItems="flex-start"
        justifyContent="flex-start"
        w="80%"
        maxW="300px"
        h="48px"
        maxH="48px"
        position="relative"
      >
        <Flex h="100%" position="relative" w="64px">
          <EqgLogoWhite
            style={{
              filter: "drop-shadow(0px 2px 4px #7090B059)",
              transform: "scale(1.3)",
              width: "60px",
            }}
          />
        </Flex>
      </Flex>

      <Spacer
        maxH={isSmallerThan700 ? "16px" : "64px"}
        minH={isSmallerThan700 ? "16px" : "64px"}
      />

      <Flex
        direction="column"
        alignItems="center"
        flex={1}
        justifyContent="center"
        w="100%"
      >
        <chakra.img
          alt="home-img"
          src={homeImg}
          h={isTablet ? { base: "25vh" } : { md: "30vh", lg: "40vh" }}
          maxH="512px"
          position="relative"
        />

        <Spacer h="24px" maxH="24px" minH="24px" />

        <Footer />
      </Flex>

      <Spacer
        maxH={isSmallerThan700 ? "40px" : "100px"}
        minH={isSmallerThan700 ? "40px" : "100px"}
      />

      <Safe
        position={isSmallerThan600 ? "relative" : "absolute"}
        bottom={isSmallerThan600 ? "0px" : "32px"}
      />
    </Flex>
  );
}
