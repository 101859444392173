import { useCallback } from "react";

import { Session } from "../storage";
import { SubmitHandler } from "react-hook-form";
import { useData } from "../stores";
import { useDefaultForm } from "../utils";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { z } from "zod";

const simulacaoSchema = z.object({
  sacarMain: z.string().nonempty("Selecione uma opção"),
  sacarSec: z.string().nonempty("Selecione uma opção"),
});

export type TSimulacaoSchema = z.infer<typeof simulacaoSchema>;

export function useSimulacaoForm() {
  const history = useHistory();
  const storeData = useData();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<TSimulacaoSchema> = useCallback(
    async (data) => {
      const result = await storeData.saveSimulacao(data);

      if (!result.error) {
        storeData.saveSimulacao(data);
        Session.setCurrentStep(3, 3);
        history.push("/dadospessoais");
      } else {
        enqueueSnackbar(
          "Ocorreu um erro ao tentar gravar os dados, tente novamente mais tarde",
          { variant: "error" }
        );
      }
    },
    [enqueueSnackbar, history, storeData]
  );

  return {
    firstName: storeData.getFirstName(),
    onSubmit,
    simResult: storeData.simResult,
    startYear: storeData.getSimStartYear(),
    endYear: storeData.getSimEndYear(),
    simYears: storeData.getSimYears(),
    simBankName: storeData.getSimBank("full"),
    ...useDefaultForm<any>({
      schema: simulacaoSchema,
      defaultValues: {
        sacarMain: storeData.sacarMain,
        sacarSec: storeData.sacarSec,
      },
    }),
  };
}
