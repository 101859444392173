import { useRef } from "react";

import { BText } from "../../../BText";
import { chakra } from "@chakra-ui/react";
import { Spacer, TextField } from "@eqg/chakra-lib.components.all";
import { useClipboard } from "@chakra-ui/hooks";

export interface AllowBank5Props {
  bankName: string;
}

export function AllowBank5({ bankName }: AllowBank5Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { hasCopied, onCopy } = useClipboard(
    inputRef?.current?.value || bankName
  );

  return (
    <>
      <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
        Pesquise por <BText>"{bankName}"</BText> e selecione.
      </chakra.p>

      <Spacer h="16px" />

      <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
        Copiar o nome do banco:
      </chakra.p>
      <Spacer h="12px" />

      <TextField
        fontFamily="primary"
        fontWeight="medium"
        value={bankName}
        readOnly
        ref={inputRef}
        rightButtonProps={{
          bg: "lightgray.400",
          color: "darkgray.700",
          fontFamily: "primary",
          fontSize: "1rem",
          onClick: onCopy,
        }}
        rightButton={hasCopied ? "Copiado" : "Copiar"}
        onClick={(e) => {
          inputRef.current?.select();
        }}
      />
    </>
  );
}
