import { useCallback, useMemo, useEffect, useState } from "react";

import { getCitiesByState } from "../services";
import { Session } from "../storage";
import { SubmitHandler } from "react-hook-form";
import { states, useDefaultForm } from "../utils";
import { useData } from "../stores";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { z } from "zod";

import {
  validateFullName,
  validateNameCharacters,
} from "@eqg/validation-lib.validations.all";

const dadosPessoaisSchema = z.object({
  motherName: z
    .string()
    .nonempty({ message: "Insira o nome completo da sua mãe" })
    .refine(
      (fullName) => validateNameCharacters(fullName),
      "Precisa ter apenas letras e espaços"
    )
    .refine(
      (fullName) => validateFullName(fullName),
      "Insira o nome completo da sua mãe"
    )
    .refine(
      (fullName) => fullName.length > 2,
      "Insira o nome completo da sua mãe"
    ),
  sex: z.string().nonempty("Selecione uma opção"),
});

const bmgDadosPessoaisSchema = z
  .object({
    birthCity: z.string().nonempty("Insira sua cidade de nascimento"),
    birthState: z.string().nonempty("Insira seu estado de nascimento"),
  })
  .merge(dadosPessoaisSchema);

const cnhFieldsSchema = z.object({
  documentType: z.literal("CNH"),
  rg: z.string().optional(),
  cnh: z.string().nonempty("Insira o número da sua CNH"),
});

const rgFieldsSchema = z.object({
  documentType: z.literal("RG"),
  rg: z.string().nonempty("Insira o número do seu RG"),
  cnh: z.string().optional(),
});

const dadosPessoaisWithDocumentSchema = z.union([
  cnhFieldsSchema.merge(bmgDadosPessoaisSchema),
  rgFieldsSchema.merge(bmgDadosPessoaisSchema),
]);

export type TDadosPessoaisSchema = z.infer<typeof dadosPessoaisSchema>;
export type TDadosPessoaisWithDocumentSchema = z.infer<
  typeof dadosPessoaisWithDocumentSchema
>;

export function useDadosPessoaisForm() {
  const history = useHistory();
  const storeData = useData();
  const { enqueueSnackbar } = useSnackbar();
  const haveDocument = storeData.simBank !== "safra";
  const haveBirthFields = storeData.simBank !== "safra";
  const [cities, setCities] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);

  const onBack = useCallback(() => {
    Session.setActiveStep(2);
    history.push("/simulacao");
  }, [history]);

  const onSubmit: SubmitHandler<TDadosPessoaisWithDocumentSchema> = useCallback(
    async (data) => {
      const result = await storeData.saveDadosPessoais(data);

      if (result.response) {
        Session.setCurrentStep(4, 4);
        history.push("/endereco");
      } else {
        enqueueSnackbar(
          "Ocorreu um erro ao tentar gravar os dados, tente novamente mais tarde",
          { variant: "error" }
        );
      }
    },
    [enqueueSnackbar, history, storeData]
  );

  const defaultValues = useMemo(
    () => ({
      birthdate: storeData.birthdate,
      motherName: storeData.motherName,
      sex: storeData.sex,
      ...(haveDocument && {
        rg: storeData.rg,
        cnh: storeData.cnh,
        documentType: storeData.documentType,
        birthCity: storeData.birthCity,
        birthState: storeData.birthState,
      }),
    }),
    [haveDocument, storeData]
  );

  const schema = haveDocument
    ? dadosPessoaisWithDocumentSchema
    : dadosPessoaisSchema;

  const form = useDefaultForm<
    TDadosPessoaisSchema | TDadosPessoaisWithDocumentSchema
  >({
    schema: schema,
    defaultValues: {
      ...defaultValues,
    },
  });

  const selectedState = form.watch("birthState");

  useEffect(() => {
    const getCities = async () => {
      setIsLoadingCities(true);
      const stateId = states.find((state) => selectedState === state.value)?.id;
      form.setValue("birthCity", "");

      if (stateId) {
        const r = await getCitiesByState(stateId);

        if (r.response) {
          const finalCities = r.response.map((city) => ({
            id: city.id,
            label: city.nome,
            value: city.nome,
          }));

          setCities(finalCities);

          if (storeData.birthCity) {
            form.setValue("birthCity", storeData.birthCity);
          }
        }
      } else {
        setCities([]);
      }
      setIsLoadingCities(false);
    };

    getCities();
  }, [selectedState, form, storeData.birthCity]);

  return {
    cities,
    isLoadingCities,
    haveBirthFields,
    haveDocument,
    onBack,
    onSubmit,
    defaultValues,
    defaultBirthdate: storeData.birthdate,
    ...form,
  };
}
