import { useCallback, useEffect, useState } from "react";

import { Session } from "../storage";
import { SubmitHandler } from "react-hook-form";
import { useData } from "../stores";
import { useDefaultForm, validateOflegalage } from "../utils";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { z } from "zod";

import {
  validateBirthdate,
  validateCpf,
  validateEmail,
  validateFullName,
  validateNameCharacters,
  validatePhone,
} from "@eqg/validation-lib.validations.all";


const identificacaoEditSchema = z.object({
  birthdate: z
    .string()
    .nonempty("Insira sua data de nascimento")
    .refine(
      (date) => validateBirthdate(date),
      "Data de nascimento inválida. Verifique os dígitos inseridos."
    )
    .refine(
      (date: any) => {
        if (date.valid === false) {
          return false;
        } else {
          return validateOflegalage(date);
        }
      }, //validateOflegalage(date)},
      "Você precisa ter 18 anos ou mais para continuar"
    ),
  cpf: z
    .string()
    .nonempty({ message: "Insira seu CPF" })
    .refine(
      (cpf) => validateCpf(cpf),
      "CPF inválido. Verifique os dígitos inseridos."
    ),
  email: z
    .string()
    .transform((val) => val.trim())
    .refine(
      (email) => (!email ? true : validateEmail(email)),
      "Insira um email válido"
    ),
  fullName: z
    .string()
    .nonempty({ message: "Insira o Primeiro e Último Nome" })
    .transform((val) => val.trim())
    .refine(
      (fullName) => validateNameCharacters(fullName),
      "insira seu nome completo"
    )
    .refine(
      (fullName) => validateFullName(fullName),
      "Insira o Primeiro e Último Nome"
    )
    .refine((fullName) => fullName.length > 2, "Insira o Primeiro e Último Nome"),
  whatsapp: z
    .string()
    .nonempty({ message: "Insira seu número do WhatsApp" })
    .length(15, "Número do WhatsApp inválido. Verifique os dígitos inseridos.")
    .refine(
      (whatsapp) => validatePhone(whatsapp),
      "Número do WhatsApp inválido. Verifique os dígitos inseridos."
    ),
});

const identificacaoEditSchema2 = z.object({
  birthdate: z
    .string()
    .nonempty("Insira sua data de nascimento")
    .refine(
      (date) => validateBirthdate(date),
      "Data de nascimento inválida. Verifique os dígitos inseridos."
    )
    .refine(
      (date: any) => {
        if (date.valid === false) {
          return false;
        } else {
          return validateOflegalage(date);
        }
      }, //validateOflegalage(date)},
      "Você precisa ter 18 anos ou mais para continuar"
    ),
  cpf: z
    .string()
    .nonempty({ message: "Insira seu CPF" })
    .refine(
      (cpf) => validateCpf(cpf),
      "CPF inválido. Verifique os dígitos inseridos."
    ),
  email: z
    .string()
    .transform((val) => val.trim())
    .refine(
      (email) => (!email ? true : validateEmail(email)),
      "Insira um email válido"
    ),
  fullName: z
    .string()
    .nonempty({ message: "Insira o seu Nome completo" })
    .transform((val) => val.trim())
    .refine(
      (fullName) => validateNameCharacters(fullName),
      "insira seu nome completo"
    )
    .refine(
      (fullName) => validateFullName(fullName),
      "Insira seu Nome completo"
    )
    .refine((fullName) => fullName.length > 2, "Insira seu Nome completo"),
  whatsapp: z
    .string()
    .nonempty({ message: "Insira seu número do WhatsApp" })
    .length(15, "Número do WhatsApp inválido. Verifique os dígitos inseridos.")
    .refine(
      (whatsapp) => validatePhone(whatsapp),
      "Número do WhatsApp inválido. Verifique os dígitos inseridos."
    ),
});

export type TIdentificacaoEditSchema = z.infer<typeof identificacaoEditSchema>;

export function useIdentificacaoEditForm() {
  const history = useHistory();
  const storeData = useData();
  const { enqueueSnackbar } = useSnackbar();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit: SubmitHandler<TIdentificacaoEditSchema> = useCallback(
    async (data) => {
      const result = await storeData.saveIdentificacao({
        ...data,
        optin: true,
      });

      if (result.response) {
        Session.setCurrentStep(2, 2);
        history.push("/simular/" + storeData.encodeData);
      } else {
        enqueueSnackbar(
          "Ocorreu um erro ao tentar gravar os dados, tente novamente mais tarde",
          { variant: "error" }
        );
      }
    },
    [enqueueSnackbar, history, storeData]
  );
  const dataName = useData();
  const condition = dataName.simBank === "c6";

  const form = useDefaultForm<TIdentificacaoEditSchema>({
    schema: condition ? identificacaoEditSchema : identificacaoEditSchema2,
    defaultValues: {
      birthdate: storeData.birthdate,
      fullName: storeData.fullName,
      cpf: storeData.cpf,
      email: storeData.email,
      whatsapp: storeData.whatsapp,
    },
  });

  /* Reset step when the home page loads */
  useEffect(() => {
    form.trigger("birthdate");
    form.trigger("cpf");
    Session.setCurrentStep(1, 1);
    // eslint-disable-next-line
  }, []);

  return {
    isModalOpen,
    setIsModalOpen,
    onSubmit,
    ...form,
  };
}
