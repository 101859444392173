import { useCallback } from "react";

import { BannerLayout, SecureEnvMobile } from "../../components";
import { Button, Checkbox, InputControl, Spacer } from "@eqg/chakra-lib.all";
import { chakra, Flex } from "@chakra-ui/react";
import { safraErrors } from "../../utils/safra-error-codes";
import { SubmitHandler } from "react-hook-form";
import { TMsgErr } from "../../services";
import { useData } from "../../stores";
import { useDefaultForm, useIsMobile } from "../../utils";
import { useHistory } from "react-router";
import { z } from "zod";

const simuleSchema = z.object({
  doneSteps: z
    .boolean()
    .refine((optin) => optin, "Você precisa aceitar para continuar"),
});

type TSimuleSchema = z.infer<typeof simuleSchema>;

export function Simular() {
  const isMobile = useIsMobile();
  const data = useData();
  const history = useHistory();

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useDefaultForm<TSimuleSchema>({
    schema: simuleSchema,
    defaultValues: { doneSteps: false },
  });

  const onSubmit: SubmitHandler<TSimuleSchema> = useCallback(
    (formData) => {
      history.push("/simular/" + data.encodeData);
    },
    [data, history]
  );

  const handleAgainClick = useCallback(() => {
    const d = data.msgErr as TMsgErr;

    if (
      d?.codigo === safraErrors.WITHDRAW_AUTH ||
      d?.codigo === safraErrors.NO_FGTS_ACCOUNT
    ) {
      history.push("/como-optar-saque/passos");
    } else if (d?.codigo === safraErrors.BANK_AUTH) {
      history.push("/autorizar-banco/passos");
    } else {
      history.push("/como-optar-saque/passos");
    }
  }, [data, history]);

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo
    >
      <Flex
        as="form"
        flexDir="column"
        maxW="306px"
        w="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <chakra.h1
          color="primary.500"
          letterSpacing="0px"
          lineHeight="33.6px"
          textStyle="title"
          fontSize="24px"
        >
          Realizou os passos anteriores? Agora é só simular!
        </chakra.h1>

        <Spacer h="36px" />

        <InputControl
          error={errors.doneSteps?.message}
          register={register("doneSteps")}
        >
          <Checkbox boxPosition="top" color="darkgray.700" fontWeight="medium">
            <chakra.p color="darkgray.700" textStyle="p">
              Sim, baixei o App do FGTS, selecionei a opção de saque aniversário
              e autorizei o banco.
            </chakra.p>
          </Checkbox>
        </InputControl>

        <Spacer h="32px" />

        <Button type="submit" borderRadius="24px">
          Simular agora
        </Button>

        <Spacer h="16px" />

        <Button
          variant="secondary"
          borderRadius="24px"
          onClick={handleAgainClick}
        >
          Ver passos novamente
        </Button>

        <Spacer h="32px" />

        <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
          Tem alguma dúvida?
        </chakra.p>

        <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
          Estamos aqui para te ajudar.
        </chakra.p>

        <Spacer h="12px" />

        <Button
          variant="link"
          fontWeight="semibold"
          onClick={() => {
            history.push("/contato");
          }}
        >
          Falar com um consultor
        </Button>

        {isMobile && (
          <>
            <Spacer h="32px" />
            <SecureEnvMobile />
          </>
        )}
      </Flex>
    </BannerLayout>
  );
}
