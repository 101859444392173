import { Box } from "@chakra-ui/layout";
import { TStepState } from "./Step";

export interface IStepDivider {
  state: TStepState;
}

export function StepDivider({ state }: IStepDivider) {
  return (
    <Box
      borderRadius="8px"
      bg={state === "completed" ? "white" : "#ffffff88"}
      h="32px"
      w="3px"
      marginLeft="23px"
      marginTop="12px"
      marginBottom="12px"
    />
  );
}
