import { chakra, Flex, FlexProps } from "@chakra-ui/react";
import { ReactComponent as EqgLogoWhite } from "../../assets/logo-eqg-white.svg";
import { Spacer } from "@eqg/chakra-lib.all";

interface BannerMobileProps extends FlexProps {}

export function BannerMobile(props: BannerMobileProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg="transparent linear-gradient(270deg, #00A1D0 0%, #00B3D5 33%, #00D2C5 67%, #16E0B0 100%) 0% 0% no-repeat padding-box;"
      h="84px"
      maxH="84px"
      minH="84px"
      padding="0px 24px"
      w="100%"
      {...props}
    >
      <Flex
        alignItems="center"
        h="100%"
        minH="100%"
        maxH="100%"
        justifyContent="space-between"
        w="100%"
        maxW="306px"
      >
        <EqgLogoWhite
          style={{
            filter: "drop-shadow(0px 2px 4px #7090B059)",
            transform: "scale(1.2)",
            width: "60px",
          }}
        />

        <Flex direction="column" alignItems="flex-end">
          <chakra.h1
            color="white"
            fontFamily="primary"
            fontSize="1.25rem"
            fontWeight="bold"
            lineHeight="33.6px"
            textAlign="end"
            textShadow="0px 2px 4px #35240E1F;"
          >
            Antecipação FGTS
          </chakra.h1>

          <Spacer h="2px" />

          <chakra.h6
            color="white"
            fontFamily="primary"
            fontSize="1.125rem"
            fontWeight="semibold"
            lineHeight="1.5rem"
            textAlign="end"
            textShadow="0px 2px 4px #35240E1F;"
          >
            Simule grátis!
          </chakra.h6>
        </Flex>
      </Flex>
    </Flex>
  );
}
