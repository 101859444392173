import { BannerLayout, Contact } from "../../components";
import { BText } from "../../components/BText";
import { chakra, Flex } from "@chakra-ui/react";
import { Spacer } from "@eqg/chakra-lib.all";
import { useData } from "../../stores";
import { useIsMobile } from "../../utils";

export function TooManyTries() {
  const isMobile = useIsMobile();

  const data = useData();

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        padding: "0px 24px",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo={true}
    >
      <Flex
        alignItems="flex-start"
        flexDir="column"
        h="100%"
        justifyContent="flex-start"
        maxW="306px"
        w="100%"
      >
        <chakra.h1
          color="primary.500"
          fontSize="20px"
          lineHeight="1.75rem"
          letterSpacing="0px"
          textStyle="title"
        >
          {data.getFirstName()}, em breve entraremos em contato para te ajudar.
        </chakra.h1>

        <Spacer h="12px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Verificamos que você não concluiu as etapas necessárias para simular o
          seu saque.
        </chakra.p>

        <Spacer h="6px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Mas não se preocupe, um de nossos consultores financeiros entrará em
          contato para te ajudar através do WhatsApp, SMS ou por ligação com o{" "}
          <BText>DDD 19</BText>. Até logo!
        </chakra.p>

        <Spacer h="32px" />

        <Contact />
      </Flex>
    </BannerLayout>
  );
}
