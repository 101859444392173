import { BText, InputControl } from "../../components";
import { chakra } from "@chakra-ui/react";
import { Form, Spacer, SubmitButton } from "@eqg/chakra-lib.all";
import { useFinalFeedbackForm } from "../../schemas/final-feedback.schema";
import { useIsMobile } from "../../utils";

import {
  AmountBox,
  BannerLayout,
  Contact,
  RatingBox,
  TextArea,
} from "../../components";

export function FinalFeedback() {
  const isMobile = useIsMobile();

  const {
    control,
    firstName,
    formState: { isSubmitting },
    register,
    handleSubmit,
    onSubmit,
  } = useFinalFeedbackForm();

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        padding: "0px 24px",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo
    >
      <Form
        flexDir="column"
        maxW="306px"
        onSubmit={handleSubmit(onSubmit)}
        w="100%"
      >
        <chakra.h4 color="primary.500" textStyle="h4">
          Parabéns, {firstName}!
        </chakra.h4>

        <Spacer h="8px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Sua solicitação de antecipação do FGTS foi enviada.
        </chakra.p>

        <Spacer h="16px" />

        <AmountBox />

        <Spacer h="16px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Em breve você receberá um <BText>SMS</BText> ou{" "}
          <BText>mensagem no WhatsApp</BText> para confirmar sua identidade.
        </chakra.p>

        <Spacer h="32px" />

        <chakra.h2
          color="primary.500"
          fontSize="1.25rem"
          fontFamily="primary"
          fontWeight="bold"
          lineHeight="1.75rem"
        >
          Sua opinião é muito importante para nós!
        </chakra.h2>

        <Spacer h="12px" />

        <chakra.p color="darkgray.500" textStyle="p">
          O quão satisfeito você ficou com o nosso atendimento?
        </chakra.p>

        <RatingBox control={control} name="rating" />

        <InputControl
          label="Conte sua experiência"
          labelHelper=" (opcional)"
          register={register("comment")}
        >
          <TextArea />
        </InputControl>

        <Spacer h="32px" />

        <SubmitButton borderRadius="24px" isLoading={isSubmitting}>
          Enviar
        </SubmitButton>

        <Spacer h="40px" />

        <Contact />
      </Form>
    </BannerLayout>
  );
}
