import { BText, FormLayout, InputControl, OptionCard } from "../../components";
import { chakra } from "@chakra-ui/react";
import { RadioboxGroup } from "@eqg/chakra-lib.all";
import { useSimulacaoForm } from "../../schemas";

export function SimulationChoices() {
  const {
    control,
    firstName,
    formState: { isSubmitting },
    handleSubmit,
    onSubmit,
    simResult,
    simBankName,
    startYear,
    watch,
  } = useSimulacaoForm();

  const sacarMain = watch("sacarMain");

  return (
    <FormLayout
      description={
        <>
          Olá, {firstName}! Sua simulação foi realizada pelo{" "}
          <BText>Banco {simBankName}</BText>. Selecione a opção que mais combina
          com você.
        </>
      }
      isLoading={isSubmitting}
      nextLabel="Sacar valor"
      noBack
      onSubmit={handleSubmit(onSubmit)}
      title="Simulação"
    >
      <InputControl label="Qual valor deseja sacar?">
        <RadioboxGroup
          control={control}
          defaultValue="5"
          flexDir="column"
          hGap="12px"
          name="sacarMain"
          options={[
            {
              value: simResult?.length + "" || "5",
              label: (
                <OptionCard
                  value={simResult?.[0].value || 5}
                  startYear={startYear}
                  endYear={simResult?.[0]?.year || 2022}
                  helperText={
                    <chakra.p color="darkgray.500" textStyle="p">
                      Essa é a <BText>MELHOR </BText>
                      opção de custo x benefício para você.
                    </chakra.p>
                  }
                />
              ),
            },
            {
              value: "other",
              label: (
                <chakra.h6
                  color={sacarMain === "other" ? "primary.500" : "darkgray.600"}
                  fontWeight={sacarMain === "other" ? "bold" : "medium"}
                  textStyle="p"
                >
                  Outro valor
                </chakra.h6>
              ),
            },
          ]}
          radioboxProps={{ justifyContent: "space-between" }}
          w="100%"
        />
      </InputControl>

      {/*<Spacer h="24px" />

      <Collapse in={sacarMain === "other"}>
        <InputControl label="Selecione o valor">
          <RadioboxGroup
            control={control}
            defaultValue="hello1"
            flexDir="column"
            hGap="12px"
            name="sacarSec"
            options={
              simResult?.slice(1).map((soma, index) => ({
                value: simResult.length - index - 1 + "",
                label: (
                  <OptionCard
                    value={soma.value}
                    startYear={startYear}
                    endYear={soma.year}
                  />
                ),
              })) || []
            }
            radioboxProps={{ justifyContent: "space-between" }}
            w="100%"
          />
        </InputControl>
        </Collapse>*/}
    </FormLayout>
  );
}
