import { chakra, Flex, FlexProps, useTheme } from "@chakra-ui/react";
import { HiOutlineLockClosed } from "react-icons/hi";

interface SafeProps extends FlexProps {
  mode?: "light" | "dark";
}

export function Safe({ mode, ...rest }: SafeProps) {
  const theme = useTheme();

  return (
    <Flex alignItems="center" justifyContent="center" {...rest}>
      <HiOutlineLockClosed
        style={{
          color: mode === "dark" ? theme.colors.blue.third : "white",
          marginRight: "8px",
          height: "20px",
          width: "20px",
        }}
      />
      <chakra.h6
        color={mode === "dark" ? "blue.third" : "white"}
        textAlign="center"
        textStyle="secure"
        fontWeight="medium"
      >
        Você está em um ambiente seguro.
      </chakra.h6>
    </Flex>
  );
}
