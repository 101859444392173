import { BannerLayout, BText, Contact } from "../../components";
import { chakra, Flex } from "@chakra-ui/react";
import { Button, Spacer } from "@eqg/chakra-lib.components.all";
import { useData } from "../../stores";
import { useIsMobile } from "../../utils";

export function InProcessC6() {
  const isMobile = useIsMobile();
  const data = useData();

  const condition = data.protocolo !== "";
  const protocolo = data.protocolo;
  const numberprotocol = condition ? "Seu protocolo de atendimento é o" : "";
  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        padding: "0px 24px",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo={true}
    >
      <Flex
        alignItems="center"
        flexDir="column"
        h="100%"
        justifyContent="start"
        maxW="306px"
        w="100%"
      >
        <chakra.h1
          color="primary.500"
          fontSize="1.25rem"
          textStyle="title"
          lineHeight="1.625rem"
        >
          Olá, {data.getFirstName()}! Sua proposta está em andamento.
        </chakra.h1>

        <Spacer h="16px" />

        <chakra.p color="darkgray.500" textStyle="p">
          {numberprotocol} <BText>{protocolo}</BText>
        </chakra.p>

        <Spacer h="16px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Realize agora mesmo seu processo de assinatura digital:
        </chakra.p>

        <Spacer h="16px" />

        <Button
          borderRadius="24px"
          onClick={() => {
            window.location.href =
              "https://api.whatsapp.com/send?phone=551124248472&text=Oi%22%3E";
          }}
          w="100%"
        >
          Clique Aqui!
        </Button>

        <Spacer h="16px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Após a aprovação dos documentos, você receberá a antecipação do saque
          na sua conta bancária
        </chakra.p>

        <Spacer h="32px" />
        <Contact />
      </Flex>
    </BannerLayout>
  );
}
