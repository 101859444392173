import { SimulationChoices, SimulationOnlyTotal } from "../../components";
import { useData } from "../../stores";

export function SimulacaoStep() {
  const data = useData();

  if (
    data.simBank === "bmg" ||
    data.simBank === "c6" ||
    data.simBank === "facta"
  ) {
    return <SimulationOnlyTotal />;
  } else {
    return <SimulationChoices />;
  }
}
