import { chakra } from "@chakra-ui/react";

export interface BTextProps {
  children: React.ReactNode;
}

export function BText({ children }: BTextProps) {
  return (
    <chakra.span color="primary.500" fontWeight="bold">
      {children}
    </chakra.span>
  );
}
