import { chakra } from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";
import { FormLayout, InputControl, RgCnhField } from "../../components";
import { states } from "../../utils";
import { useData } from "../../stores";

import {
  RadioboxGroup,
  Spacer,
  TextField,
  Combobox,
} from "@eqg/chakra-lib.components.all";

import {
  TDadosPessoaisWithDocumentSchema,
  useDadosPessoaisForm,
} from "../../schemas";

export function DadosPessoaisStep() {
  const {
    cities,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    haveBirthFields,
    haveDocument,
    isLoadingCities,
    onBack,
    onSubmit,
    register,
    watch,
  } = useDadosPessoaisForm();

  const documentType = watch("documentType");
  const sex = watch("sex");

  const data = useData();
  const condition = data.simBank === "c6";
  const maxlength = condition ? 35 : 999;

  return (
    <FormLayout
      backLabel="Voltar"
      description="Para sua segurança, precisamos confirmar seus dados."
      isLoading={isSubmitting}
      nextLabel="Continuar"
      onBack={onBack}
      onSubmit={handleSubmit(onSubmit)}
      title="Dados Pessoais"
    >
      {haveDocument && (
        <>
          <RgCnhField
            control={
              control as Control<TDadosPessoaisWithDocumentSchema, object>
            }
            error={errors.documentType?.message}
          />

          <Spacer h="20px" />

          {documentType === "RG" && (
            <InputControl
              error={errors.rg?.message}
              label="Número do RG"
              register={register("rg")}
            >
              <TextField autoComplete="new-password" />
            </InputControl>
          )}

          {documentType === "CNH" && (
            <InputControl
              error={errors.cnh?.message}
              label="Número da CNH"
              register={register("cnh")}
            >
              <TextField autoComplete="new-password" />
            </InputControl>
          )}

          <Spacer h="24px" />
        </>
      )}

      <InputControl
        error={errors.motherName?.message}
        label="Nome da mãe"
        register={register("motherName")}
      >
        <TextField maxLength={maxlength} />
      </InputControl>

      <Spacer h="24px" />

      <InputControl error={errors.sex?.message} label="Sexo">
        <RadioboxGroup
          control={control}
          flexDir="column"
          hGap="16px"
          name="sex"
          options={[
            {
              value: "M",
              label: (
                <chakra.h6
                  fontWeight={sex === "M" ? "bold" : "medium"}
                  textStyle="p"
                >
                  Masculino
                </chakra.h6>
              ),
            },
            {
              value: "F",
              label: (
                <chakra.h6
                  fontWeight={sex === "F" ? "bold" : "medium"}
                  textStyle="p"
                >
                  Feminino
                </chakra.h6>
              ),
            },
          ]}
          radioboxProps={{ justifyContent: "space-between" }}
          w="100%"
        />
      </InputControl>

      {haveBirthFields && (
        <>
          <Spacer h="24px" />

          <InputControl
            error={errors.birthState?.message}
            label="Estado de Nascimento"
          >
            <Controller
              control={control}
              name="birthState"
              render={({ field }) => {
                return (
                  <Combobox
                    autoComplete="new-password"
                    bg="white"
                    name={field.name}
                    options={states}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={errors.birthState?.message}
                    value={field.value}
                  />
                );
              }}
            />
          </InputControl>

          <Spacer h="24px" />

          <InputControl
            error={errors.birthCity?.message}
            label="Cidade de Nascimento"
          >
            <Controller
              control={control}
              name="birthCity"
              render={({ field }) => {
                return (
                  <Combobox
                    autoComplete="new-password"
                    bg="white"
                    name={field.name}
                    options={cities}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    isLoading={isLoadingCities}
                    error={errors.birthCity?.message}
                    value={field.value}
                  />
                );
              }}
            />
          </InputControl>
        </>
      )}
    </FormLayout>
  );
}
