import { FormLayout, InputControl } from "../../components";
import { MaskedField, Spacer, TextField } from "@eqg/chakra-lib.components.all";
import { useEnderecoForm } from "../../schemas";
import { useData } from "../../stores";

export function EnderecoStep() {
  const {
    formState: { errors, isSubmitting },
    isCepLoading,
    handleSubmit,
    onBack,
    onSubmit,
    register,
  } = useEnderecoForm();

  const data = useData();
  const condition = data.simBank === "c6";
  const maxlength = condition ? 35 : 999;
  const maxlengthbairro = condition ? 35 : 999;
  const maxlengthnumero = condition ? 6 : 8;

  return (
    <FormLayout
      backLabel="Voltar"
      description="Para completar seu perfil, precisamos das informações de onde você mora."
      isLoading={isSubmitting}
      nextLabel="Continuar"
      onBack={onBack}
      onSubmit={handleSubmit(onSubmit)}
      title="Endereço Residencial"
    >
      <InputControl
        error={errors.cep?.message}
        isLoading={isCepLoading}
        label="CEP"
        register={register("cep")}
      >
        <MaskedField
          keyboard="numeric"
          mask="99999-999"
          placeholder="00000-000"
        />
      </InputControl>

      <Spacer h="24px" />

      <InputControl label="Endereço" register={register("address")}>
        <TextField maxLength={maxlength} />
      </InputControl>

      <Spacer h="24px" />

      <InputControl label="Bairro" register={register("neighborhood")}>
        <TextField maxLength={maxlengthbairro} />
      </InputControl>

      <Spacer h="24px" />

      <InputControl isDisabled label="Estado" register={register("state")}>
        <TextField />
      </InputControl>

      <Spacer h="24px" />

      <InputControl isDisabled label="Cidade" register={register("city")}>
        <TextField />
      </InputControl>

      <Spacer h="24px" />

      <InputControl
        error={errors.num?.message}
        label="Número"
        register={register("num")}
      >
        <TextField keyboard="numeric" maxLength={maxlengthnumero} />
      </InputControl>

      <Spacer h="24px" />

      <InputControl
        error={errors.complement?.message}
        label="Complemento"
        labelHelper=" (opcional)"
        register={register("complement")}
      >
        <TextField maxLength={25} placeholder="Apartamento, Bloco etc." />
      </InputControl>

      <Spacer h="24px" />
    </FormLayout>
  );
}
