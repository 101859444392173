import create from "zustand";
import { persist } from "zustand/middleware";

import {
  banks,
  cellphoneToApi,
  cepToApi,
  cpfToApi,
  cpfToStore,
  dateToApi,
  dateToStore,
} from "../utils";

import {
  IResponse,
  createClient,
  ICreateClientRes,
  ISimulacaoReq,
  IClientRes,
  simulateClient,
  TMsgErr,
  editClient,
  deleteClient,
  sendClient,
  sendFeedback,
  TDocType,
  TSimulationResult,
  ICampaignCookie,
} from "../services";

import {
  TDadosBancariosSchema,
  TDadosPessoaisWithDocumentSchema,
  TEnderecoSchema,
  TFinalFeedbackSchema,
  TIdentificacaoSchema,
  TSimulacaoSchema,
} from "../schemas";

export interface IDataStore {
  account: string;
  accountDigit: string;
  accountType: string;
  address: string;
  agency: string;
  //agencyDigit: string;
  bank: string;
  bankAuth: string;
  bestContact: string;
  birthCity: string;
  birthdate: string;
  birthState: string;
  cep: string;
  city: string;
  cnh: string;
  codigoErro: number;
  comment: string;
  complement: string | null;
  cpf: string;
  documentType: TDocType | "";
  email: string;
  emissor: "SSP" | "";
  encodeData: string;
  fullName: string;
  id: number | null;
  iof: number;
  isLoading: boolean;
  libDate: string;
  monthlyTax: number;
  motherName: string;
  msgErr: TMsgErr | {} | null;
  neighborhood: string;
  num: string;
  optin: boolean;
  rating: number | null;
  rg: string;
  sacarMain: string;
  sacarSec: string;
  saqueAuth: string;
  sex: string;
  simBank: keyof typeof banks;
  simResult: TSimulationResult | null;
  simResultTotal: number | null;
  simValue: number;
  state: string;
  whatsapp: string;
  protocolo: string;
  setEncodeData: (newEncodeData: string) => void;
  getFirstName: () => string;
  getLastName: () => string;
  getSimBank: (type: "short" | "full") => string;
  getSimEndYear: () => number;
  getSimStartYear: () => number;
  getSimYears: () => number;
  mapClientFromApiToStore: (apiClient: IClientRes) => void;
  saveDadosBancarios: (
    data: TDadosBancariosSchema
  ) => Promise<IResponse<IClientRes>>;
  saveDadosPessoais: (
    data: TDadosPessoaisWithDocumentSchema
  ) => Promise<IResponse<IClientRes>>;
  saveEndereco: (data: TEnderecoSchema) => Promise<IResponse<IClientRes>>;
  saveFeedback: (data: TFinalFeedbackSchema) => Promise<IResponse<{}>>;
  saveIdentificacao: (
    data: TIdentificacaoSchema,
    codigo?: string | null,
    cookieData?: ICampaignCookie
  ) => Promise<IResponse<ICreateClientRes>>;
  saveSimulacao: (data: TSimulacaoSchema) => Promise<IResponse<IClientRes>>;
  simulate: (data: ISimulacaoReq) => Promise<IResponse<IClientRes>>;
  deleteSimulacao: () => Promise<IResponse<{}>>;
  sendClient: () => Promise<IResponse<{}>>;
  reset: () => void;
}

export const defaultValues = {
  account: "",
  accountDigit: "",
  accountType: "",
  address: "",
  agency: "",
  //agencyDigit: "",
  bank: "",
  bankAuth: "",
  bestContact: "",
  birthCity: "",
  birthdate: "",
  birthState: "",
  cep: "",
  city: "",
  cnh: "",
  codigoErro: -1,
  comment: "",
  complement: "",
  cpf: "",
  documentType: "RG" as TDocType,
  email: "",
  emissor: "" as "",
  encodeData: "",
  fullName: "",
  id: null,
  iof: 0,
  isLoading: false,
  libDate: "",
  monthlyTax: 0,
  motherName: "",
  msgErr: null,
  neighborhood: "",
  num: "",
  optin: true,
  rating: null,
  rg: "",
  sacarMain: "5",
  sacarSec: "3",
  saqueAuth: "",
  sex: "",
  simBank: "bmg" as keyof typeof banks,
  simResult: null,
  simResultTotal: null,
  simValue: 0,
  state: "",
  whatsapp: "",
  protocolo: "012345",
};

export const useData = create<IDataStore>(
  persist(
    (set, get) => ({
      ...defaultValues,
      setEncodeData(newEncodeData: string) {
        set((state) => ({ ...state, encodeData: newEncodeData }));
      },
      getFirstName() {
        return get().fullName.split(" ")?.[0];
      },
      getLastName() {
        return get().fullName.split(" ")?.[1];
      },
      getSimBank(type: "short" | "full") {
        if (type === "short") {
          return banks[get().simBank].shortName;
        } else {
          return banks[get().simBank].name;
        }
      },
      getSimStartYear(): number {
        return 2023;
        /*const simResult = get().simResult;

        if (simResult && simResult.length > 0) {
          return simResult[0]?.year;
        } else {
          return 2022;
        }*/
      },
      getSimEndYear(): number {
        return 2032;
        /*const simResult = get().simResult;

        if (simResult && simResult.length > 0) {
          return simResult[simResult.length - 1]?.year;
        } else {
          return 2022;
        }*/
      },
      getSimYears(): number {
        return get().getSimEndYear() - get().getSimStartYear() + 1;
      },
      saveDadosBancarios: async (data: TDadosBancariosSchema) => {
        const result = await editClient({
          id: get().id || -1,
          cpf: cpfToApi(get().cpf),
          agencia: data.agency,
          //agenciaDigito: data.agencyDigit,
          banco: data.bank,
          conta: data.account,
          contaDigito: data.accountDigit,
          tipoConta: data.accountType,
        });

        if (result.response) {
          set((state) => ({ ...state, ...data }));
        }

        return result;
      },
      saveDadosPessoais: async (data: TDadosPessoaisWithDocumentSchema) => {
        const result = await editClient({
          id: get().id || -1,
          cpf: cpfToApi(get().cpf),
          sexo: data.sex,
          nomeMae: data.motherName,
          tipoDocumento: data.documentType,
          numeroDocumento: data.documentType === "CNH" ? data.cnh : data.rg,
          cidadeNascimento: data.birthCity,
          ufNascimento: data.birthState,
        });

        if (result.response) {
          set((state) => ({
            ...state,
            ...data,
            cnh: data.documentType === "CNH" ? data.cnh : "",
            rg: data.documentType === "RG" ? data.rg : "",
          }));
        }
        return result;
      },
      saveEndereco: async (data: TEnderecoSchema) => {
        let address = data.address;

        if (data.address) {
          let tempArray = address.split("-");
          tempArray.splice(tempArray.length - 1, 1);

          address = tempArray.join("-");
          address = address.trim();
        }

        const result = await editClient({
          id: get().id || -1,
          cpf: cpfToApi(get().cpf),
          bairro: data.neighborhood,
          cep: cepToApi(data.cep),
          cidade: data.city,
          complemento: data.complement ? data.complement : undefined,
          numero: data.num,
          rua: address || data.address,
          uf: data.state,
        });

        if (result.response) {
          set((state) => ({ ...state, ...data }));
        }
        return result;
      },
      saveFeedback: async (data: TFinalFeedbackSchema) => {
        const result = await sendFeedback({
          id: get().id || -1,
          comentario: data.comment,
          nota: data.rating || undefined,
        });

        if (result.response) {
          get().reset();
        }

        return result;
      },
      saveIdentificacao: async (
        data: TIdentificacaoSchema,
        codigo?: string | null,
        cookieData?: ICampaignCookie
      ) => {
        const result = await createClient({
          cpf: cpfToApi(data.cpf),
          celular: cellphoneToApi(data.whatsapp),
          codigo: codigo,
          dtNascimento: dateToApi(data.birthdate),
          email: data.email,
          nome: data.fullName,
          campaignCookie: cookieData,
        });

        if (result.response) {
          const { response } = result;

          set((state) => ({
            ...state,
            ...data,
            encodeData: response.encode_data,
          }));
        }
        return result;
      },
      saveSimulacao: async (data: TSimulacaoSchema) => {
        const result = await editClient({
          id: get().id || -1,
          cpf: cpfToApi(get().cpf),
          saldoAcumulado:
            data.sacarMain !== "other"
              ? parseInt(data.sacarMain)
              : parseInt(data.sacarSec),
        });

        if (result.response) {
          set((state) => ({ ...state, ...data }));
        }

        return result;
      },
      simulate: async (data: ISimulacaoReq) => {
        const result = await simulateClient({
          token: data.token,
          codigo: data.codigo,
        });

        const { response } = result;

        if (response) {
          get().mapClientFromApiToStore(response);
        }

        return result;
      },
      deleteSimulacao: async () => {
        const result = await deleteClient({
          id: get().id || -1,
        });

        if (result.response) {
          get().reset();
        }

        return result;
      },
      sendClient: async () => {
        const result = await sendClient({ id: get().id || -1 });
        if (result.response) {
          console.log(result.response.id_proposta_bmg);
          set((state) => ({
            ...state,
            protocolo: result.response?.id_proposta_bmg || "",
          }));
        }
        return result;
      },
      reset: () => {
        set((state) => ({ ...state, ...defaultValues }));
      },
      mapClientFromApiToStore: (apiClient: IClientRes) => {
        const simulation = parseSimResult(apiClient);

        set((state) => ({
          ...state,
          id: apiClient.id || -1,
          cpf: cpfToStore(apiClient.cpf || ""),
          fullName: apiClient.nome || "",
          motherName: apiClient.nome_mae || "",
          whatsapp: apiClient.celular || "",
          email: apiClient.email || "",
          encodeData: apiClient.encode_data || "",
          birthdate: dateToStore(apiClient.dt_nascimento || ""),
          sex: apiClient.sexo || "",
          saldoAcumulado: apiClient.saldo_acumulado_safra || null,
          sacarMain:
            !apiClient.saldo_acumulado_safra ||
            apiClient.saldo_acumulado_safra === 5
              ? "5"
              : "other",
          sacarSec:
            apiClient.saldo_acumulado_safra &&
            apiClient.saldo_acumulado_safra >= 1 &&
            apiClient.saldo_acumulado_safra <= 4
              ? apiClient.saldo_acumulado_safra + ""
              : "4",

          // Endereco
          address:
            apiClient.endereco?.logradouro || apiClient.endereco?.rua || "",
          neighborhood: apiClient.endereco?.bairro || "",
          city: apiClient.endereco?.cidade || "",
          state: apiClient.endereco?.uf || "",
          complement: apiClient.endereco?.complemento || "",
          num: apiClient.endereco?.numero || "",
          cep: apiClient.endereco?.cep || "",
          // TODO: PARSE MSG ERR BMG
          msgErr: apiClient.msg_err_bmg || null,
          // dadosBancarios
          bank: apiClient.dadosBancarios?.banco || "",
          account: apiClient.dadosBancarios?.conta_num || "",
          accountDigit: apiClient.dadosBancarios?.conta_digito || "",
          accountType: apiClient.dadosBancarios?.tipoConta || "",
          agency: apiClient.dadosBancarios?.agencia_num || "",
          //agencyDigit: apiClient.dadosBancarios?.agencia_digito || "",
          // identidade
          rg:
            apiClient.identidade?.tipo === "RG"
              ? apiClient.identidade?.numero || ""
              : "",
          cnh:
            apiClient.identidade?.tipo === "CNH"
              ? apiClient.identidade?.numero || ""
              : "",
          documentType: apiClient.identidade?.tipo || "RG",
          birthState: apiClient.identidade?.uf || "",
          birthCity: apiClient.identidade?.cidade_nasc || "",
          monthlyTax: apiClient.simulacao_bmg?.taxaContratoAM || 0,
          iof: apiClient.simulacao_bmg?.valorIOFPorcentagem || 0,
          simValue: apiClient.simulacao_bmg?.valorLiberado || 0,
          // Simulation
          simBank: simulation?.simBank || "bmg",
          simResult: simulation?.simResult || null,
          simResultTotal: simulation?.simResultTotal || null,
        }));
      },
    }),
    { name: "data-storage", getStorage: () => sessionStorage }
  )
);

function parseSimResult(apiClient: IClientRes) {
  let simBank = null as keyof typeof banks | null;

  if (apiClient.flag === 1) {
    simBank = "bmg";
  } else if (apiClient.flag === 2) {
    simBank = "safra";
  } else if (apiClient.flag === 4) {
    simBank = "c6";
  } else if (apiClient.flag === 5) {
    simBank = "facta";
  }

  return {
    simBank: simBank as keyof typeof banks,
    simResult: null,
    simResultTotal: apiClient.simulacao_bmg?.valorLiberado || null,
  };
}
