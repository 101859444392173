import React from "react";

import { BiUser } from "react-icons/bi";
import { FaRegAddressCard } from "react-icons/fa";
import { Flex, FlexProps, useBreakpointValue } from "@chakra-ui/react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdAttachMoney } from "react-icons/md";
import { ReactComponent as EqgLogoWhite } from "../../assets/logo-eqg-white.svg";
import { RiBankLine } from "react-icons/ri";
import { Safe } from "..";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { Step, TStepState } from "./Step";
import { StepDivider } from "./StepDivider";

export const steps = [
  {
    name: "Identificação",
    shortName: "Identificação",
    num: 1,
    icon: <FaRegAddressCard fontSize="28px" />,
  },
  {
    name: "Simulação",
    shortName: "Simulação",
    num: 2,
    icon: <MdAttachMoney fontSize="32px" />,
  },
  {
    name: "Dados Pessoais",
    shortName: "Dados Pessoais",
    num: 3,
    icon: <BiUser fontSize="30px" />,
  },
  {
    name: "Endereço Residencial",
    shortName: "Endereço",
    num: 4,
    icon: <HiOutlineLocationMarker fontSize="32px" />,
  },
  {
    name: "Dados Bancários",
    shortName: "Dados Bancários",
    num: 5,
    icon: <RiBankLine fontSize="28px" />,
  },
];

export interface IStepperDesktop extends FlexProps {
  active: number;
}

export function StepperDesktop({ active, ...rest }: IStepperDesktop) {
  const xd = useBreakpointValue({ xl: "560px", lg: "400px", md: "340px" });
  return (
    <Flex
      bg="transparent linear-gradient(90deg, #00A1D0 0%, #00B3D5 33%, #00D2C5 67%, #16E0B0 100%) 0% 0% no-repeat padding-box;"
      flexDir="column"
      h="100vh"
      maxH="100vh"
      overflow="hidden"
      maxW="560px"
      w={xd}
      {...rest}
    >
      <Flex
        alignItems="center"
        flex={1}
        flexDir="column"
        overflow="visible"
        overflowY="auto"
        paddingTop="41px"
        w="100%"
      >
        <Flex w="300px" overflow="visible">
          <EqgLogoWhite
            style={{
              filter: "drop-shadow(0px 2px 4px #7090B059)",
              transform: "scale(1.5)",
              width: "56px",
            }}
          />
        </Flex>
        <Spacer h="64px" />
        <Flex flex={1}>
          <Flex flexDir="column" w="268px">
            {steps.map((step) => {
              let currentState: TStepState = "next";

              if (step.num === active) {
                currentState = "current";
              } else if (step.num < active) {
                currentState = "completed";
              }

              return (
                <React.Fragment key={step.name}>
                  <Step state={currentState} {...step} />
                  {step.num !== steps[steps.length - 1].num && (
                    <StepDivider state={currentState} />
                  )}
                </React.Fragment>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      <Safe padding="32px 0px" w="100%" />
    </Flex>
  );
}
