import { Button, ButtonProps } from "@eqg/chakra-lib.all";
import { FaApple, FaGooglePlay } from "react-icons/fa";

export interface DownloadButtonProps extends ButtonProps {
  isIOS: boolean;
  isOpen?: boolean;
}

export function DownloadButton({
  isIOS,
  isOpen,
  ...rest
}: DownloadButtonProps) {
  return (
    <Button
      borderRadius="24px"
      Icon={
        isIOS ? (
          <FaApple fontSize="1.3rem" style={{ marginTop: "-2px" }} />
        ) : (
          <FaGooglePlay fontSize="1.3rem" style={{ marginTop: "-2px" }} />
        )
      }
      onClick={() => {
        window.location.href = isIOS
          ? "https://apps.apple.com/br/app/fgts/id1038441027"
          : "https://play.google.com/store/apps/details?id=br.gov.caixa.fgts.trabalhador";
      }}
      w="243px"
      {...rest}
    >
      {isOpen ? "Abrir" : "Instalar"} o app FGTS
    </Button>
  );
}
