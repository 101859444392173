import { brlFormat } from "../../utils";
import { chakra, Flex, FlexProps } from "@chakra-ui/react";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { useData } from "../../stores";

interface AmountBoxProps extends FlexProps {}

export function AmountBox(props: AmountBoxProps) {
  const data = useData();

  /*const withdraw = useMemo(() => {
    if (data.sacarMain === "5") {
      const soma = data.simResult?.[0];

      return { value: soma?.value, startYear: startYear, endYear: soma?.year };
    } else {
      const soma = data.simResult?.[5 - parseInt(data.sacarSec)];
      return { value: soma?.value, startYear: startYear, endYear: soma?.year };
    }
  }, [data, startYear]);*/

  return (
    <Flex
      bg="#58CC4714"
      borderRadius="8px"
      flexDir="column"
      padding="16px"
      w="100%"
      {...props}
    >
      <chakra.p color="darkgray.700" textStyle="p">
        Valor do saque FGTS
      </chakra.p>

      <Spacer h="8px" />

      <chakra.h1 color="success.800" fontSize="1.625rem" textStyle="moneyText">
        {brlFormat.format(data.simResultTotal || 0)}
      </chakra.h1>

      <Spacer h="8px" />

      <chakra.p color="darkgray.700" fontSize="0.875rem" textStyle="p">
        {data.getSimYears()} anos ({data.getSimStartYear()} até{" "}
        {data.getSimEndYear()})
      </chakra.p>
    </Flex>
  );
}
