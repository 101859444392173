import { brlFormat } from "../../utils";
import { chakra, Flex, FlexProps } from "@chakra-ui/react";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { useData } from "../../stores";

interface TotalBoxProps extends FlexProps {}

export function TotalBox(props: TotalBoxProps) {
  const data = useData();

  const withdraw = data.simValue;

  return (
    <Flex
      bg="#58CC4714"
      borderRadius="8px"
      flexDir="column"
      padding="16px"
      w="100%"
      {...props}
    >
      <chakra.p color="darkgray.700" textStyle="p">
        Valor total que você recebe:
      </chakra.p>

      <Spacer h="8px" />

      <chakra.h1 color="success.800" fontSize="1.625rem" textStyle="moneyText">
        {brlFormat.format(withdraw || 0)}
      </chakra.h1>

      <Spacer h="8px" />

      <chakra.p color="darkgray.700" fontSize="0.875rem" textStyle="p">
        {/*{data.getSimYears()} anos ({data.getSimStartYear()} até{" "}
        {data.getSimEndYear()})*/}
        10 anos (2023 até 2032)
      </chakra.p>
    </Flex>
  );
}
