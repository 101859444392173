import { Button, Spacer } from "@eqg/chakra-lib.components.all";
import { Flex } from "@chakra-ui/layout";
import { SecureEnvMobile } from "./SecureEnvMobile";
import { useIsMobile } from "../utils";

export interface FormActionsProps {
  backLabel?: string;
  cancelLabel?: string;
  isLoading?: boolean;
  noBack?: boolean;
  noForm?: boolean;
  nextLabel: string;
  onBack?: () => void;
  onCancel?: () => void;
  onNext?: () => void;
}

export function FormActions({
  backLabel,
  cancelLabel,
  isLoading,
  noBack,
  noForm,
  nextLabel,
  onBack,
  onCancel,
  onNext,
}: FormActionsProps) {
  const isMobile = useIsMobile();

  return (
    <>
      <Flex justifyContent="space-between">
        {!noBack && (
          <>
            <Button
              _hover={{
                bg: "primary.50",
              }}
              borderRadius="24px"
              isLoading={isLoading}
              onClick={onBack}
              variant="secondary"
              w="50%"
            >
              {backLabel}
            </Button>

            <Spacer w="16px" />
          </>
        )}

        <Button
          borderRadius="24px"
          isLoading={isLoading}
          onClick={() => {
            if (onNext) {
              onNext();
            }
          }}
          type={noForm ? "button" : "submit"}
          w={noBack ? "100%" : "50%"}
        >
          {nextLabel}
        </Button>
      </Flex>

      <Spacer h="20px" />

      {cancelLabel && (
        <>
          <Spacer h="4px" />
          <Button onClick={onCancel} variant="link">
            {cancelLabel || "Cancelar simulação"}
          </Button>
          {isMobile && <Spacer h="24px" />}
        </>
      )}

      {isMobile && <SecureEnvMobile />}
    </>
  );
}
