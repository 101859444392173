import { useCallback } from "react";

import { Flex, FlexProps } from "@chakra-ui/react";
import { Spacer } from "@eqg/chakra-lib.components.all";

import {
  Happy,
  HappyActive,
  Reg,
  RegActive,
  Sad,
  SadActive,
  SuperHappy,
  SuperHappyActive,
  SuperSad,
  SuperSadActive,
} from "../../assets/icons";
import {
  useController,
  FieldValues,
  Path,
  UseControllerProps,
} from "react-hook-form";

export interface RatingBoxProps<T extends FieldValues>
  extends Omit<FlexProps, "defaultValue" | "name">,
    UseControllerProps<T, Path<T>> {}

export function RatingBox<T extends FieldValues>({
  control,
  defaultValue,
  name,
  ...rest
}: RatingBoxProps<T>) {
  const {
    field: { onBlur, onChange, value },
  } = useController<T>({
    control: control,
    defaultValue: defaultValue,
    name: name,
  });

  const onClick = useCallback(
    (n: number) => {
      onChange(n);
    },
    [onChange]
  );

  return (
    <Flex onBlur={onBlur} w="100%" {...rest}>
      {value === 1 ? (
        <SuperSadActive />
      ) : (
        <SuperSad
          onClick={() => {
            onClick(1);
          }}
        />
      )}

      <Spacer w="12px" />

      {value === 2 ? (
        <SadActive />
      ) : (
        <Sad
          onClick={() => {
            onClick(2);
          }}
        />
      )}

      <Spacer w="12px" />

      {value === 3 ? (
        <RegActive />
      ) : (
        <Reg
          onClick={() => {
            onClick(3);
          }}
        />
      )}

      <Spacer w="12px" />

      {value === 4 ? (
        <HappyActive />
      ) : (
        <Happy
          onClick={() => {
            onClick(4);
          }}
        />
      )}

      <Spacer w="12px" />

      {value === 5 ? (
        <SuperHappyActive />
      ) : (
        <SuperHappy
          onClick={() => {
            onClick(5);
          }}
        />
      )}
    </Flex>
  );
}
