import axios from "axios";
import { IResponse } from "./types";

export async function getStates(): Promise<IResponse<any>> {
  try {
    const response = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados/"
    );

    return { response: response.data };
  } catch (error) {
    return { error: error };
  }
}

export async function getCitiesByState(
  stateId: string
): Promise<IResponse<{ id: string; nome: string }[]>> {
  try {
    const response = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`
    );

    return { response: response.data };
  } catch (error) {
    return { error: error };
  }
}
