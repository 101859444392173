import { api } from "./api";
import { AxiosError } from "axios";
import { ICreateClientReq } from "./types";
import { IResponse } from "./types";
import { ISendFeedbackReq } from ".";

import {
  IClientRes,
  ICreateClientRes,
  IDeleteClientReq,
  IEditClientReq,
  ISendClientReq,
  ISimulacaoReq,
} from "./types";

export async function createClient({
  celular,
  cpf,
  codigo,
  dtNascimento,
  email,
  nome,
  campaignCookie,
}: ICreateClientReq): Promise<IResponse<ICreateClientRes>> {

  try {
    const result = await api.post<ICreateClientRes>("/form/create", {
      celular: celular,
      cpf: cpf,
      dt_nascimento: dtNascimento,
      email: email,
      nome: nome,
      ...(codigo && { codigo: codigo }),
      ...(campaignCookie?.gclid && {
        gclid: campaignCookie?.gclid,
      }),
      ...(campaignCookie?.fbclid && {
        fbclid: campaignCookie?.fbclid,
      }),
      ...(campaignCookie?.utm_source && {
        utm_source: campaignCookie.utm_source,
      }),
      ...(campaignCookie?.utm_campaign && {
        utm_campaign: campaignCookie?.utm_campaign,
      }),
      ...(campaignCookie?.utm_medium && {
        utm_medium: campaignCookie?.utm_medium,
      }),
      ...(campaignCookie?.utm_term && {
        utm_term: campaignCookie?.utm_term,
      }),
      ...(campaignCookie?.utm_content && {
        utm_content: campaignCookie?.utm_content,
      }),
      ...(campaignCookie?.utm_id && {
        utm_id: campaignCookie?.utm_id,
      }),
      ...(campaignCookie?.aff_click_id && {
        aff_click_id: campaignCookie?.aff_click_id,
      })
    });
    var encode_data = result.data.encode_data
    sessionStorage.setItem("encode_data", encode_data);
    const dataencode = sessionStorage.getItem("encode_data")
    console.log(dataencode)
    return { response: result.data };
  } catch (error: any) {
    const aError = error as AxiosError;

    return { error: error, errorRes: aError?.response?.data };
  }
}

export async function simulateClient({
  token,
  codigo,
}: ISimulacaoReq): Promise<IResponse<IClientRes>> {
  try {
    const result = await api.put<IClientRes>("/form/simulacao/" + token, {
      ...(codigo && { codigo: codigo }),
    });

    return { response: result.data };
  } catch (error: any) {
    const aError = error as AxiosError;
    return { error: error, errorRes: aError?.response?.data };
  }
}

export async function editClient({
  id,
  cpf,
  saldoAcumulado,
  dtNascimento,
  nomeMae,
  sexo,
  bairro,
  cep,
  cidade,
  cidadeNascimento,
  complemento,
  numero,
  numeroDocumento,
  rua,
  uf,
  ufNascimento,
  agencia,
  agenciaDigito,
  banco,
  conta,
  contaDigito,
  tipoConta,
  tipoDocumento,
}: IEditClientReq): Promise<IResponse<IClientRes>> {
  try {
    const endereco = {
      ...(bairro && { bairro: bairro }),
      ...(cep && { cep: cep }),
      ...(cidade && { cidade: cidade }),
      ...(complemento && { complemento: complemento }),
      ...(numero && { numero: numero }),
      ...(rua && { rua: rua }),
      ...(uf && { uf: uf }),
    };
    let hasEndereco = true;

    if (Object.keys(endereco).length === 0) {
      hasEndereco = false;
    }

    const dadosBancarios = {
      ...(banco && { banco: banco }),
      ...(agencia && { agencia_num: agencia }),
      ...(agenciaDigito && { agencia_digito: agenciaDigito }),
      ...(tipoConta && { tipoConta: tipoConta }),
      ...(conta && { conta_num: conta }),
      ...(contaDigito && { conta_digito: contaDigito }),
    };

    let hasDadosBancarios = true;

    if (Object.keys(dadosBancarios).length === 0) {
      hasDadosBancarios = false;
    }

    const identidade = {
      ...(cidadeNascimento && { cidade_nasc: cidadeNascimento }),
      ...(ufNascimento && { uf: ufNascimento }),
      ...(tipoDocumento && { tipo: tipoDocumento }),
      ...(numeroDocumento && { numero: numeroDocumento }),
    };

    let hasIdentidade = true;

    if (Object.keys(identidade).length === 0) {
      hasIdentidade = false;
    }
    const encode_data = sessionStorage.getItem("encode_data")
    const result = await api.post<IClientRes>("/form/update/" + encode_data, {
      cpf: cpf,
      ...(saldoAcumulado && { saldo_acumulado: saldoAcumulado }),
      ...(dtNascimento && { dt_nascimento: dtNascimento }),
      ...(nomeMae && { nome_mae: nomeMae }),
      ...(sexo && { sexo: sexo }),
      ...(hasEndereco && {
        endereco: {
          ...(bairro && { bairro: bairro }),
          ...(cep && { cep: cep }),
          ...(cidade && { cidade: cidade }),
          ...(complemento && { complemento: complemento }),
          ...(numero && { numero: numero }),
          ...(rua && { logradouro: rua }),
          ...(uf && { uf: uf }),
        },
      }),
      ...(hasDadosBancarios && {
        dadosBancarios: { ...dadosBancarios },
      }),
      ...(hasIdentidade && {
        identidade: {
          ...identidade,
        },
      }),
    });

    return { response: result.data };
  } catch (error: any) {
    const aError = error as AxiosError;
    return { error: error, errorRes: aError?.response?.data };
  }
}

export async function deleteClient({
  id,
}: IDeleteClientReq): Promise<IResponse<{}>> {
  try {
    const encode_data = sessionStorage.getItem("encode_data")
    const result = await api.get("/safra/delete/" + encode_data);

    return { response: result.data };
  } catch (error: any) {
    const aError = error as AxiosError;
    return { error: error, errorRes: aError?.response?.data };
  }
}

export async function sendClient({
  id,
}: ISendClientReq): Promise<IResponse<{ id_proposta_bmg: string }>> {
  try {
    const encode_data = sessionStorage.getItem("encode_data")
    const result = await api.put("/form/proposta/" + encode_data);

    return { response: result.data };
  } catch (error: any) {
    const aError = error as AxiosError;
    return { error: error, errorRes: aError?.response?.data };
  }
}

export async function sendFeedback({
  id,
  comentario,
  nota,
}: ISendFeedbackReq): Promise<IResponse<{}>> {
  try {
    const result = await api.post("/form/feedback/", {
      cliente_id: id,
      ...(comentario && { comentario: comentario }),
      ...(nota && { nota: nota }),
    });

    return { response: result.data };
  } catch (error: any) {
    const aError = error as AxiosError;
    return { error: error, errorRes: aError?.response?.data };
  }
}

var request = require("request");
var options = {
  method: "GET",
  url: api.get("/form/"),
};
request(options, function () { });
