import { Flex } from "@chakra-ui/react";
import { Spacer } from "@eqg/chakra-lib.components.all";

export interface CarouselItemProps {
  children: React.ReactNode;
  imgAlt: string;
  imgSrc: string;
}

export function CarouselItem({ children, imgAlt, imgSrc }: CarouselItemProps) {
  return (
    <Flex flexDir="column" w="100%">
      <Flex
        alignItems="center"
        bg="lightgray.300"
        borderRadius="8px"
        minHeight="458px"
        justifyContent="center"
        padding="16px 38px"
        width="100%"
      >
        <img alt={imgAlt} src={imgSrc} />
      </Flex>

      <Spacer h="16px" />

      {children}
    </Flex>
  );
}
