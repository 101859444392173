export const banks = {
  bmg: { id: "BMG", name: "MASTER S.A", shortName: "MASTER" },
  safra: { id: "SAFRA", name: "J. Safra S.A", shortName: "Safra" },
  c6: { id: "C6", name: "C6 Consignado S.A.", shortName: "C6" },
  facta: {
    id: "FACTA",
    name: "Facta Financeira S.A.",
    shortName: "Facta",
  },
};
