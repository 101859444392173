import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactComponent as EqgLogoWhite } from "../../assets/logo-eqg-white.svg";

export interface BannerMobileOnlyLogoProps extends FlexProps {}

export function BannerMobileOnlyLogo(props: BannerMobileOnlyLogoProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg="radial-gradient(circle at 50% 20%, #16e0b0, #00d8bc, #00d0c6, #00c8ce, #00bfd3, #00b5d5, #00abd4, #00a1d0);"
      h="60px"
      maxH="60px"
      minH="60px"
      padding="0px 24px"
      w="100%"
      {...props}
    >
      <EqgLogoWhite
        style={{
          filter: "drop-shadow(0px 2px 4px #7090B059)",
          transform: "scale(1.1)",
          width: "60px",
        }}
      />
    </Flex>
  );
}
