import { BText } from "../../../BText";
import { chakra } from "@chakra-ui/react";

export function AllowBank7() {
  return (
    <>
      <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
        Clique em <BText>Confirmar seleção</BText>.
      </chakra.p>
    </>
  );
}
