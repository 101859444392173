import { BText } from "../../../BText";
import { chakra } from "@chakra-ui/react";

export function AllowWithdraw4() {
  return (
    <>
      <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
        Selecione a opção <BText>Saque Aniversário do FGTS</BText> no menu
        principal.
      </chakra.p>
    </>
  );
}
