import { forwardRef } from "react";

import { Textarea, TextareaProps, useTheme } from "@chakra-ui/react";

export interface TextAreaProps extends TextareaProps {}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea({ ...rest }: TextAreaProps, ref) {
    const theme = useTheme();
    return (
      <Textarea
        minH="150px"
        ref={ref}
        autoComplete="off"
        bg="white"
        borderColor="lightgray.600"
        borderWidth="1px"
        borderRadius="base"
        color="darkgray.700"
        fontFamily="secondary"
        fontSize="1rem"
        height="tall"
        textStyle="body1"
        _focus={{
          border: `1.5px solid ${theme.colors.primary[500]}`,
          boxShadow: `0px 0px 4px ${theme.colors.primary[500]}`,
        }}
        _hover={{
          borderColor: "darkgray.100",
        }}
        _placeholder={{
          color: "lightgray.800",
          textStyle: "body1",
          fontFamily: "secondary",
        }}
        _disabled={{
          bg: "lightgray.300",
          color: "darkgray.300",
          cursor: "not-allowed",
          _hover: {
            borderColor: "lightgray.600",
          },
        }}
        placeholder="Escreva sua mensagem..."
        {...rest}
      />
    );
  }
);
