//export const x = null;

import React from "react";
import TextTransition, { presets } from "react-text-transition";

export interface TransitionLoadingProps {
  texts: string[];
  transitionTime?: number;
}
export const TransitionLoading = ({
  texts,
  transitionTime = 3000,
}: TransitionLoadingProps) => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => (index + 1) % texts.length),
      transitionTime // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, [transitionTime, texts]);

  return (
    <h1>
      <TextTransition text={texts[index]} springConfig={presets.gentle} />
    </h1>
  );
};
