import { CarouselItem } from "./CarouselItem";
import CarouselLib from "react-elastic-carousel";
import { CarouselNav } from "./CarouselNav";
import { Flex } from "@chakra-ui/react";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { useHistory } from "react-router";
import {
  Union,
  ResponsiveValue,
} from "@chakra-ui/styled-system/dist/types/utils";

export type TCarouselItem = {
  id: string;
  alt: string;
  content: React.ReactNode;
  navMargin?: ResponsiveValue<
    Union<
      | number
      | "px"
      | (string & {})
      | "-moz-initial"
      | "inherit"
      | "initial"
      | "revert"
      | "unset"
      | "auto"
    >
  >;
  src: string;
};

export interface CarouselProps {
  backUrl: string;
  items: TCarouselItem[];
}

export function Carousel({ backUrl, items }: CarouselProps) {
  const history = useHistory();

  return (
    <Flex flexDir="column" w="100%">
      <CarouselLib
        isRTL={false}
        itemsToShow={1}
        renderArrow={() => <></>}
        renderPagination={({ activePage, pages, onClick }) => {
          return (
            <CarouselNav
              active={activePage}
              numOfItems={pages.length}
              mt={items[activePage].navMargin}
              onBack={() => {
                if (activePage > 0) {
                  onClick(activePage - 1 + "");
                } else {
                  history.push(backUrl);
                }
              }}
              onNext={() => {
                if (activePage < items.length - 1) {
                  onClick(activePage + 1 + "");
                } else {
                  history.push("/simule");
                }
              }}
              zIndex="100000"
            />
          );
        }}
      >
        {items.map((item) => {
          return (
            <CarouselItem key={item.id} imgAlt={item.alt} imgSrc={item.src}>
              {item.content}
              <Spacer h="32px" />
            </CarouselItem>
          );
        })}
      </CarouselLib>
    </Flex>
  );
}
