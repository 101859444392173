import { useCallback } from "react";

import { Button, Spacer } from "@eqg/chakra-lib.components.all";
import { Session } from "../storage";
import { useData } from "../stores";
import { useHistory } from "react-router";

import {
  chakra,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { BText } from ".";

export interface SimulationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SimulationModal({ isOpen, onClose }: SimulationModalProps) {
  const history = useHistory();
  const data = useData();

  const handleSimulateClick = useCallback(() => {
    Session.setCurrentStep(2, 2);
    history.push("/simular/" + data.encodeData);
  }, [data, history]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent w="300px" maxW="90%">
        <Flex alignItems="center" flexDir="column" padding="32px 24px" w="100%">
          <chakra.h3
            color="primary.500"
            fontSize="1.5rem"
            fontWeight="bold"
            textAlign="center"
            textStyle="p"
          >
            Vamos começar!
          </chakra.h3>

          <Spacer h="12px" />

          <chakra.p
            color="darkgray.500"
            fontSize="0.875rem"
            textAlign="center"
            textStyle="p"
          >
            Clique no botão abaixo para realizar sua simulação.
          </chakra.p>

          <Spacer h="6px" />

          <chakra.p
            color="darkgray.500"
            fontSize="0.875rem"
            textAlign="center"
            textStyle="p"
          >
            Você também receberá um link por <BText>SMS</BText> ou{" "}
            <BText>WhatsApp</BText> para acompanhar o processo e retomá-lo
            quando quiser.
          </chakra.p>

          <Spacer h="24px" />

          <Button borderRadius="24px" onClick={handleSimulateClick} w="100%">
            Simular grátis
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
