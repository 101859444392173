export function validateOflegalage(birthdate: string) {
  if (!birthdate && birthdate?.length > 0) {
    return false;
  }
  let splitBirthdate = birthdate.split("/");
  if (splitBirthdate.length !== 3) {
    return false;
  }
  const day = parseInt(splitBirthdate[0]);

  const month = parseInt(splitBirthdate[1]);
  const year = parseInt(splitBirthdate[2]);
  let data = new Date();
  let currentYear = data.getFullYear();
  let presentday = data.getDate();
  let currentmonth = data.getMonth() + 1;
  let age = currentYear - year;
  if (
    age < 18 ||
    (age === 18 &&
      (currentmonth < month || (currentmonth === month && presentday < day)))
  ) {
    return false;
  }
  return true;
}
