import { useCallback, useMemo } from "react";

import { BannerLayout, BText, SecureEnvMobile } from "../../../components";
import { Button, Spacer } from "@eqg/chakra-lib.all";
import { chakra, Flex } from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { useData } from "../../../stores";
import { useHistory } from "react-router";
import { useIsMobile } from "../../../utils";

export function AllowWithdrawInit() {
  const isMobile = useIsMobile();
  const history = useHistory();
  const data = useData();
  const [cookies] = useCookies(["tutorialTries"]);

  const handleNextClick = useCallback(() => {
    history.push("/como-optar-saque/passos");
  }, [history]);

  const tries = useMemo(
    () => cookies.tutorialTries?.[data.cpf + "bmg"] || 1,
    [cookies.tutorialTries, data.cpf]
  );

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo
    >
      <Flex flexDir="column" maxW="306px" w="100%">
        <chakra.h1
          color="primary.500"
          lineHeight="1.75rem"
          textStyle="title"
          fontSize="1.25rem"
          fontWeight="bold"
        >
          {tries <= 1
            ? `Olá, ${data.getFirstName()}! Veja como optar pelo Saque e autorizar o
            banco.`
            : `${data.getFirstName()}, verificamos que os passos anteriores não foram concluídos.`}
        </chakra.h1>

        <Spacer h="16px" />

        {tries > 1 && (
          <chakra.p color="darkgray.500" textStyle="p">
            Para realizarmos sua simulação, você deve optar pelo Saque
            Aniversário FGTS e autorizar o Banco {data.getSimBank("full")}.
          </chakra.p>
        )}

        {tries <= 1 && (
          <>
            <Spacer h="4px" />

            <chakra.p color="darkgray.500" textStyle="p">
              Para realizarmos sua simulação, é necessário que você
              <BText> siga os passos a seguir</BText>. É rápido e fácil, vamos
              lá?
            </chakra.p>
          </>
        )}

        <Spacer h={tries <= 1 ? "40px" : "32px"} />

        <Button borderRadius="24px" onClick={handleNextClick}>
          {tries <= 1 ? "Começar" : "Ver passos novamente"}
        </Button>

        {tries > 1 && (
          <>
            {" "}
            <Spacer h="32px" />
            <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
              Tem alguma dúvida?
            </chakra.p>
            <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
              Estamos aqui para te ajudar.
            </chakra.p>
            <Spacer h="12px" />
            <Button
              variant="link"
              fontWeight="semibold"
              onClick={() => {
                history.push("/contato");
              }}
            >
              Falar com um consultor
            </Button>
          </>
        )}

        {isMobile && (
          <>
            <Spacer h="20px" />
            <SecureEnvMobile />
          </>
        )}
      </Flex>
    </BannerLayout>
  );
}
