import { BText } from "../../../BText";
import { chakra } from "@chakra-ui/react";

export function AllowBank8() {
  return (
    <>
      <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
        Clique em <BText>Sim</BText> para concluir a operação.
      </chakra.p>
    </>
  );
}
