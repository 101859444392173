export function cpfToApi(cpf: string) {
  return cpf.replace(/[.-]/g, "");
}

export function cpfToStore(cpf: string) {
  if (!cpf) {
    return "";
  }

  return (
    cpf.slice(0, 3) +
    "." +
    cpf.slice(3, 6) +
    "." +
    cpf.slice(6, 9) +
    "-" +
    cpf.slice(9)
  );
}

export function dateToApi(date: string) {
  const splitDate = date.split("/");
  return splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
}

export function dateToStore(date: string) {
  if (!date) {
    return "";
  }

  const splitDate = date.split("-");
  return splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];
}

export function cepToApi(cep: string) {
  return cep.replace(/[-]/g, "");
}

export function cepToStore(cep: string) {
  if (!cep) {
    return "";
  }

  if (cep[5] === "-") {
    return cep;
  }

  return cep.slice(0, 5) + "-" + cep.slice(5);
}

export function cellphoneToApi(cellphone: string) {
  return cellphone.replace(/[()-\s]/g, "");
}

export function sexToStore(sex: string) {
  if (!sex) {
    return "";
  } else if (sex === "F") {
    return "Feminino";
  } else if (sex === "M") {
    return "Masculino";
  }
}

export const bankMap = {
  "1": "Banco do Brasil",
  "33": "Santander",
  "74": "Safra",
  "104": "Caixa Econômica Federal",
  "237": "Bradesco",
  "318": "BMG S.A",
  "341": "Itaú",
};

export function bankToStore(bank: string) {
  const b = bank as keyof typeof bankMap;
  if (!bank) {
    return "";
  } else {
    return bankMap[b];
  }
}

export function accountTypeToStore(accountType: string) {
  if (!accountType) {
    return "";
  } else if (accountType === "CC") {
    return "Conta Corrente";
  } else if (accountType === "PP") {
    return "Conta Poupança";
  }
}

export const brlFormat = new Intl.NumberFormat("pt-BR", {
  currency: "BRL",
  style: "currency",
});
