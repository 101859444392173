import { zodResolver } from "@hookform/resolvers/zod";
import { DeepPartial, UnpackNestedValue, useForm } from "react-hook-form";

export function useDefaultForm<T>({
  schema,
  defaultValues,
}: {
  schema: any;
  defaultValues: UnpackNestedValue<DeepPartial<T>>;
}) {
  return useForm<T>({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onBlur",
    defaultValues: defaultValues,
  });
}
