import { chakra, Flex } from "@chakra-ui/react";
import { FormLayout, InputControl } from "../../components";
import { useDadosBancariosForm } from "../../schemas";

import {
  FieldError,
  MaskedField,
  Select,
  Spacer,
} from "@eqg/chakra-lib.components.all";

export function DadosBancariosStep() {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    onBack,
    onSubmit,
    register,
  } = useDadosBancariosForm();
  // console.log(options.value)
  return (
    <FormLayout
      backLabel="Voltar"
      description="Você está quase lá! Informe os dados da sua conta bancária, que você deseja receber o seu saque."
      isLoading={isSubmitting}
      nextLabel="Continuar"
      onBack={onBack}
      onSubmit={handleSubmit(onSubmit)}
      title="Dados Bancários"
    >
      <InputControl
        error={errors.bank?.message}
        label="Banco"
        register={register("bank")}
      >
        <Select
          name="bank"
          options={[
            { label: "001 - Banco do Brasil", value: "1" },
            { label: "033 - Santander", value: "33" },
            { label: "041 - Banrisul", value: "41" },
            { label: "074 - Safra", value: "74" },
            { label: "104 - Caixa Econômica Federal", value: "104" },
            { label: "237 - Bradesco", value: "237" },
            { label: "318 - BMG S.A", value: "318" },
            { label: "341 - Itaú", value: "341" },
            { label: "748 - Sicredi", value: "748" },
            { label: "756 - Sicoob", value: "756" },
          ]}
          placeholder="Selecionar..."
        />
      </InputControl>

      <Spacer h="24px" />

      <InputControl error={errors.accountType?.message}
       label="Tipo de conta" 
       register={register("accountType")}
       >
      <Select
          name="accountType"
          options={[
            { label: "Corrente", value: "CC" },
            { label: "Poupança", value: "PP" },
          ]}
          placeholder="Selecionar..."
        />
      </InputControl>

      <Spacer h="24px" />

      <Flex flexDir="column" w="100%">
        <Flex w="100%">
          <InputControl
            error={errors.agency?.message ? true : false}
            label={
              <chakra.p>
                Nº da agência{" "}
                <chakra.span fontWeight="medium">(sem dígito)</chakra.span>
              </chakra.p>
            }
            register={register("agency")}
          >
            <MaskedField keyboard="numeric" mask="9999" />
          </InputControl>

          {/*<Spacer w="16px" />
          <InputControl
            error={errors.agencyDigit?.message ? true : false}
            label="Dígito"
            register={register("agencyDigit")}
            w="30%"
          >
            <MaskedField keyboard="numeric" mask="99" />
        </InputControl>*/}
        </Flex>

        <Spacer h="8px" />

        {errors.agency?.message && (
          <FieldError message="Insira o número da sua agência" />
        )}

        {/*errors.agencyDigit?.message && !errors.agency?.message && (
          <FieldError message={errors.agencyDigit?.message} />
        )*/}
      </Flex>

      <Spacer h="24px" />

      <Flex flexDir="column" w="100%">
        <Flex w="100%">
          <InputControl
            error={errors.account?.message ? true : false}
            label="Nº da conta"
            register={register("account")}
            w="100%"
          >
            <MaskedField keyboard="numeric" mask="999999999999999" />
          </InputControl>

          <Spacer w="16px" />

          <InputControl
            error={errors.accountDigit?.message ? true : false}
            label="Dígito"
            register={register("accountDigit")}
            w="30%"
          >
            <MaskedField keyboard="numeric" mask="9" />
          </InputControl>
        </Flex>

        <Spacer h="8px" />

        {errors.account?.message && errors.accountDigit?.message && (
          <FieldError message="Insira o número e dígito da sua conta" />
        )}

        {errors.account?.message && !errors.accountDigit?.message && (
          <FieldError message={errors.account?.message} />
        )}

        {errors.accountDigit?.message && !errors.account?.message && (
          <FieldError message={errors.accountDigit?.message} />
        )}
      </Flex>
    </FormLayout>
  );
}
