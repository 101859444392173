import { useEffect } from "react";

import { BannerLayout } from "../../components";
import { chakra, Flex, Progress } from "@chakra-ui/react";
import { loading } from "../../assets";
import { Session } from "../../storage";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { TMsgErr } from "../../services";
import { TransitionLoading } from "../../components/loading-animation/TransitionLoading";
import { useData } from "../../stores";
import { useCookies } from "react-cookie";
import { useHistory, useParams } from "react-router";
import { useIsMobile, useQuery } from "../../utils";

export function LoadingStep() {
  const data = useData();
  const history = useHistory();
  const isMobile = useIsMobile();
  const { token } = useParams<{ token: string }>();
  const [cookies, setCookie] = useCookies(["tutorialTries"]);
  const query = useQuery();

  const phone = data.whatsapp
  .replace("(", "")
  .replace(")", "")
  .replace(" ", "")
  .replace("-", "");

  const texts = [
    "Confirmando seus dados cadastrais…",
    "Verificando ativação e autorização do seu FGTS…",
    `Realizando sua simulação pelo Banco ${data.getSimBank("full")}…`,
  ];

  useEffect(() => {
    const simulate = async () => {
      const codigo = query.get("codigo");
      const { response } = await data.simulate({ token, codigo });

      if (response) {
        // Already have a proposal in some bank
        if (response.id_proposta_bmg || response.id_proposta_safra) {
          history.push(`/proposta-em-andamento/${data.simBank}`);
          return;
        }

        const hasSimulation = response.simulacao_bmg;

        const msgErr =
          response.flag === 0 ? response.msg_err_safra : response.msg_err_bmg;

        const hasMsgErr =
          (response?.msg_err_safra && response?.msg_err_safra !== {}) ||
          (response?.msg_err_bmg && response?.msg_err_bmg !== {});

        // Go to resume
        if (hasSimulation && response.sexo) {
          //@ts-ignore
          window.dataLayer.push({
            event: "fgts-cpf-valido",
            email: data.email,
            phone_number: `+55${phone}`,
            "address.first_name": data.getFirstName(),
            "address.last_name": data.getLastName(),
            "address.street": data.address,
            "address.city": data.city,
            "address.region": data.state,
            "address.postal_code": data.cep,
            "address.country": "Br",
          });
          Session.setCurrentStep(5, 5);
          history.push("/continuar");
          // Go to simulation
        } else if (hasSimulation) {
          //@ts-ignore
          window.dataLayer.push({
            event: "fgts-cpf-valido",
            email: data.email,
            phone_number: `+55${phone}`,
            "address.first_name": data.getFirstName(),
            "address.last_name": data.getLastName(),
            "address.street": data.address,
            "address.city": data.city,
            "address.region": data.state,
            "address.postal_code": data.cep,
            "address.country": "Br",
          });
          Session.setCurrentStep(2, 2);
          history.push("/simulacao");
          // Simulation error
        } else if (hasMsgErr) {
          const err = msgErr as TMsgErr;
          //@ts-ignore
          window.dataLayer.push({ event: "fgts-cpf-nao-valido", 
          email: data.email,
          phone_number: `+55${phone}`,
          "address.first_name": data.getFirstName(),
          "address.last_name": data.getLastName(),
          "address.street": data.address,
          "address.city": data.city,
          "address.region": data.state,
          "address.postal_code": data.cep,
          "address.country": "Br",});
          
          const tries = cookies.tutorialTries?.[data.cpf + data.simBank];
          //const tries = 0;

          if (err.codigo === 9) {
            if (tries >= 2) {
              // history.push("/entraremos-em-contato");
            } else {
              setCookie(
                "tutorialTries",
                {
                  [data.cpf + data.simBank]:
                    (cookies.tutorialTries?.[data.cpf + data.simBank] || 0) + 1,
                },
                { maxAge: 604800 }
              );

//               history.push("/como-optar-saque");
            }
          } else if (err.codigo === 7) {
            if (tries >= 2) {
//               history.push("/entraremos-em-contato");
            } else {
              setCookie(
                "tutorialTries",
                {
                  [data.cpf + data.simBank]:
                    (cookies.tutorialTries?.[data.cpf + data.simBank] || 0) + 1,
                },
                { maxAge: 604800 }
              );
//               history.push("/autorizar-banco");
            }
          } else if (err.codigo === 406) {
            history.push("/fora-de-servico");
          } else if (err.codigo === 20) {
            history.push("/data-indisponivel");
          } else if (err.codigo === 14 || err.codigo === 17) {
            history.push(`/proposta-em-andamento/${data.simBank}`);
          } else if (
            err.codigo === 8 ||
            err.codigo === 10 ||
            err.codigo === 11 ||
            err.codigo === 12 ||
            err.codigo === 15
          ) {
//             history.push("/outra-oportunidade");
          } else if (
              err.codigo === 6000
          ) {
//             history.push("/erro");
          } else {
            history.push("/contato");
          }
        }
      }
    };

    simulate();
    // eslint-disable-next-line
  }, []);

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        paddingTop: isMobile ? "24px" : "64px",
      }}
    >
      <Flex alignItems="center" flexDir="column" maxW="306px" w="100%">
        <Spacer h="108px" />

        <chakra.img alt="Carregando simulação" src={loading} w="102px" />

        <Spacer h="24px" />

        <Progress
          borderRadius="4px"
          h="8px"
          hasStripe
          isIndeterminate
          w="100%"
        />

        <Spacer h="24px" />

        <chakra.h6
          color="darkgray.700"
          textAlign="center"
          textStyle="p"
          padding="0px 12px"
        >
          <TransitionLoading texts={texts} transitionTime={2500} />
        </chakra.h6>
      </Flex>
    </BannerLayout>
  );
}
