import React, { useState } from "react";

import { Button, Spacer } from "@eqg/chakra-lib.components.all";
import { IoIosArrowDown } from "react-icons/io";
import { Session } from "../../storage";
import { useData } from "../../stores";
import { useHistory } from "react-router";

import {
  accountTypeToStore,
  bankToStore,
  brlFormat,
  cepToStore,
  sexToStore,
} from "../../utils";

import {
  Accordion,
  AccordionItem,
  AccordionItemProps,
  AccordionButton,
  AccordionButtonProps,
  AccordionPanel,
  AccordionPanelProps,
  Box,
  chakra,
  Flex,
  FlexProps,
} from "@chakra-ui/react";

export function Summary() {
  const data = useData();
  const history = useHistory();

  return (
    <Flex>
      <Accordion defaultIndex={[0]} w="100%" allowMultiple>
        <SummaryItem
          defaultIsOpen
          editTitle="Ver detalhes"
          items={[
            {
              field: "Valor",
              value: brlFormat.format(data.simResultTotal || 0),
            },
            {
              field: "Período",
              value: (
                <>
                  {data.getSimYears()} anos{" "}
                  <chakra.span fontWeight="medium">
                    ({data.getSimStartYear()} - {data.getSimEndYear()})
                  </chakra.span>
                </>
              ),
            },
            {
              field: "Banco",
              value: data.getSimBank("full"),
            },
          ]}
          onEditClick={() => {
            Session.setCurrentStep(2, 2);
            history.push("/simulacao");
          }}
          title="Simulação do saque"
        />

        <Spacer h="8px" />

        <SummaryItem
          items={[
            { field: "Nome", value: data.fullName },
            {
              field: "CPF",
              value: data.cpf,
            },
            { field: "Nascimento", value: data.birthdate },
            {
              field: "WhatsApp",
              value: data.whatsapp,
            },
            ...(data.email
              ? [
                  {
                    field: "Email",
                    value: data.email || "Não fornecido",
                  },
                ]
              : []),
          ]}
          onEditClick={() => {
            Session.setCurrentStep(1, 1);
            history.push("/identificacao");
          }}
          title="Identificação"
        />

        <Spacer h="8px" />

        {data.sex && (
          <>
            <SummaryItem
              items={[
                {
                  field: data.documentType,
                  value: data.documentType === "RG" ? data.rg : data.cnh,
                },
                {
                  field: "Mãe",
                  value: data.motherName,
                },
                {
                  field: "Sexo",
                  value: sexToStore(data.sex),
                },
                {
                  field: "Estado de nasc.",
                  value: data.birthState,
                },
                {
                  field: "Cidade de nasc.",
                  value: (
                    <chakra.span>
                      <Box
                        maxW="130px"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {data.birthCity}
                      </Box>
                    </chakra.span>
                  ),
                },
              ]}
              onEditClick={() => {
                Session.setCurrentStep(3, 3);
                history.push("/dadospessoais");
              }}
              title="Dados Pessoais"
            />

            <Spacer h="8px" />
          </>
        )}

        {data.cep && (
          <>
            <SummaryItem
              items={[{ field: "CEP", value: cepToStore(data.cep || "") }]}
              onEditClick={() => {
                Session.setCurrentStep(4, 4);
                history.push("/endereco");
              }}
              title="Endereço"
              addon={
                <chakra.p color="darkgray.700" fontWeight="bold" textStyle="p">
                  {data.address}, {data.num}, {data.neighborhood}. {data.city} -{" "}
                  {data.state}
                  <Spacer h="10px" />
                </chakra.p>
              }
            />

            <Spacer h="8px" />
          </>
        )}

        {data.bank && (
          <SummaryItem
            items={[
              { field: "Banco", value: bankToStore(data.bank) },
              {
                field: "Tipo de conta",
                value: accountTypeToStore(data.accountType),
              },
              {
                field: "Nº da agência",
                value: `${data.agency}`,
              },
              {
                field: "Nº da conta",
                value: `${data.account}${
                  data.accountDigit ? `-${data.accountDigit}` : ""
                }`,
              },
            ]}
            onEditClick={() => {
              Session.setCurrentStep(5, 5);
              history.push("/dadosbancarios");
            }}
            title="Dados Bancários"
          />
        )}
      </Accordion>
    </Flex>
  );
}

type TSummaryPanelItem = { field: string; value: React.ReactNode };

interface SummaryItemProps extends AccordionItemProps {
  addon?: React.ReactNode;
  defaultIsOpen?: boolean;
  editTitle?: string;
  items: TSummaryPanelItem[];
  noEdit?: boolean;
  onEditClick?: () => void;
  title: string;
}

function SummaryItem({
  addon,
  defaultIsOpen,
  editTitle,
  items,
  noEdit,
  onEditClick,
  title,
  ...rest
}: SummaryItemProps) {
  return (
    <AccordionItem w="100%" {...rest}>
      <SummaryButton defaultIsOpen={defaultIsOpen} text={title} />

      <Box bg="lightgray.500" h="1px" w="100%" />

      <SummaryPanel
        addon={addon}
        editTitle={editTitle}
        items={items}
        onEditClick={onEditClick}
        noEdit={noEdit}
      />
    </AccordionItem>
  );
}

interface SummaryButtonProps extends AccordionButtonProps {
  defaultIsOpen?: boolean;
  text: string;
}

function SummaryButton({ defaultIsOpen, text, ...rest }: SummaryButtonProps) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen || false);

  return (
    <AccordionButton
      borderRadius="8px"
      color="darkgray.700"
      h="48px"
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      w="100%"
      _hover={{ bg: "transparent" }}
      {...rest}
    >
      <chakra.h4
        color="primary.500"
        flex={1}
        textAlign="left"
        fontSize="1.125rem"
        textStyle="h5"
      >
        {text}
      </chakra.h4>
      <IoIosArrowDown
        style={{
          fontSize: "24px",
          transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.3s",
        }}
      />
    </AccordionButton>
  );
}

interface SummaryPanelProps extends AccordionPanelProps {
  addon?: React.ReactNode;
  editTitle?: string;
  items: TSummaryPanelItem[];
  noEdit?: boolean;
  onEditClick?: () => void;
}

function SummaryPanel({
  addon,
  editTitle,
  items,
  noEdit,
  onEditClick,
  ...rest
}: SummaryPanelProps) {
  return (
    <AccordionPanel
      display="flex"
      flexDir="column"
      justifyContent="center"
      padding="0px"
      {...rest}
    >
      <SummaryPanelItems items={items} />
      {addon}
      {!noEdit && (
        <>
          <Button variant="link" onClick={onEditClick}>
            {editTitle || "Editar"}
          </Button>
          <Spacer h="4px" />
        </>
      )}
    </AccordionPanel>
  );
}

interface SummaryPanelItemsProps extends FlexProps {
  items: TSummaryPanelItem[];
}

function SummaryPanelItems({ items, ...rest }: SummaryPanelItemsProps) {
  return (
    <Flex flexDir="column" padding="24px 0px 0px 0px" w="100%" {...rest}>
      {items.map((item) => (
        <React.Fragment key={item.field}>
          <SummaryPanelItem field={item.field} value={item.value} />
          <Spacer h="16px" />
        </React.Fragment>
      ))}
    </Flex>
  );
}

interface SummaryPanelItemProps extends FlexProps {
  field: string;
  value: React.ReactNode;
}

function SummaryPanelItem({ field, value, ...rest }: SummaryPanelItemProps) {
  return (
    <Flex justifyContent="space-between" {...rest}>
      <chakra.p color="darkgray.400" textStyle="p">
        {field}
      </chakra.p>

      <Box
        marginBottom="6px"
        borderBottom="2px dotted green"
        borderColor="lightgray.800"
        flex={1}
        marginLeft="8px"
        marginRight="8px"
        minW="10px"
      />

      <chakra.p
        color="darkgray.700"
        fontSize="1rem"
        fontWeight="bold"
        textStyle="p"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {value}
      </chakra.p>
    </Flex>
  );
}
