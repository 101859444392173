import { FormLayout, InputControl } from "../../components";
import { TextField, MaskedField, Spacer } from "@eqg/chakra-lib.all";
import { useIdentificacaoEditForm } from "../../schemas";
import { useData } from "../../stores";

export function IdentificacaoEditStep() {
  const {
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
    onSubmit,
  } = useIdentificacaoEditForm();

  const data = useData();
  const condition = data.simBank === "c6";
  const currentlabel = condition ? "Primeiro e Último Nome" : "Nome completo";
  const maxlength = condition ? 35 : 999;

  return (
    <FormLayout
      description=""
      isLoading={isSubmitting}
      nextLabel="Continuar"
      noBack
      onSubmit={handleSubmit(onSubmit)}
      title="Identificação"
    >
      <InputControl
        error={errors.fullName?.message}
        label={currentlabel}
        register={register("fullName")}
      >
        <TextField maxLength={maxlength}></TextField>
      </InputControl>
      <Spacer h="24px" />

      <InputControl label="CPF">
        <MaskedField
          isDisabled
          keyboard="numeric"
          mask="999.999.999-99"
          placeholder={data.cpf}
        />
      </InputControl>

      <Spacer h="24px" />

      <InputControl
        error={errors.birthdate?.message}
        label="Data de nascimento"
        register={register("birthdate")}
      >
        <MaskedField
          keyboard="numeric"
          mask="99/99/9999"
          placeholder="dd/mm/aaaa"
        />
      </InputControl>

      <Spacer h="24px" />

      <InputControl
        error={errors.whatsapp?.message}
        label="WhatsApp"
        labelHelper=" (com DDD)"
        register={register("whatsapp")}
      >
        <MaskedField
          keyboard="tel"
          mask="(99) 99999-9999"
          placeholder="(00) 00000-0000"
        />
      </InputControl>

      <Spacer h="24px" />

      <InputControl
        error={errors.email?.message}
        label="Email"
        labelHelper=" (opcional)"
        register={register("email")}
        helperText="O email é nossa melhor forma de comunicação."
      >
        <TextField placeholder="email@dominio.com" />
      </InputControl>
    </FormLayout>
  );
}
