import { useMemo } from "react";
import { BannerDesktop } from "./BannerDesktop";
import { BannerMobile } from "./BannerMobile";
import { BannerMobileCustomTitle } from "./BannerMobileCustomTitle";
import { BannerMobileOnlyLogo } from "./BannerMobileOnlyLogo";
import { Flex, FlexProps, Spacer } from "@chakra-ui/react";
import { useIsMobile } from "../../utils";

export interface BannerLayoutProps {
  children: React.ReactNode;
  containerProps?: FlexProps;
  customTitle?: string;
  onlyLogo?: boolean;
}

export function BannerLayout({
  children,
  containerProps,
  customTitle,
  onlyLogo,
}: BannerLayoutProps) {
  const isMobile = useIsMobile();

  const bannerToRender = useMemo(() => {
    if (isMobile && customTitle) {
      return <BannerMobileCustomTitle title={customTitle} />;
    } else if (isMobile && onlyLogo) {
      return <BannerMobileOnlyLogo />;
    } else if (isMobile) {
      return <BannerMobile />;
    } else {
      return <BannerDesktop />;
    }
  }, [customTitle, isMobile, onlyLogo]);

  return (
    <Flex
      bg="lightgray.200"
      direction={{ base: "column", md: "row" }}
      overflow={isMobile ? "auto" : "hidden"}
      h="100%"
      w="100%"
    >
      {bannerToRender}

      <Flex
        bg="lightgray.200"
        flex={1}
        flexDir="column"
        padding="0px 24px"
        overflow={isMobile ? "visible" : "auto"}
        {...containerProps}
      >
        {children}
        <Spacer h="32px" minH="32px" />
      </Flex>
    </Flex>
  );
}
