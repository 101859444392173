import { useCallback, useState } from "react";

import { Button, Spacer } from "@eqg/chakra-lib.components.all";
import { imgCancelar } from "../assets";
import { useData } from "../stores";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";

import {
  chakra,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

export interface CancelSimulationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function CancelSimulationModal({
  isOpen,
  onClose,
}: CancelSimulationModalProps) {
  const data = useData();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = useCallback(async () => {
    setIsLoading(true);
    const result = await data.deleteSimulacao();

    setIsLoading(false);

    if (result.error) {
      enqueueSnackbar(
        "Não foi possível cancelar a simulação, tente novamente mais tarde",
        { variant: "error" }
      );
    } else {
      enqueueSnackbar("Simulação cancelada.", {
        variant: "info",
      });
      history.push("/");
    }
  }, [data, enqueueSnackbar, history]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent w="300px" maxW="90%">
        <Flex alignItems="center" flexDir="column" padding="32px 24px" w="100%">
          <img
            alt="cancelar simulação"
            src={imgCancelar}
            style={{ height: "54px", width: "54px" }}
          />

          <Spacer h="16px" />

          <chakra.h3
            color="primary.500"
            fontSize="20px"
            fontWeight="bold"
            textStyle="p"
          >
            Cancelar simulação?
          </chakra.h3>

          <Spacer h="8px" />

          <chakra.p
            color="darkgray.500"
            fontSize="0.875rem"
            textAlign="center"
            textStyle="p"
          >
            Ao cancelar, seus dados pessoais serão apagados de nossos sistemas.
          </chakra.p>

          <Spacer h="6px" />

          <chakra.p
            color="darkgray.500"
            fontSize="0.875rem"
            textAlign="center"
            textStyle="p"
          >
            Não se preocupe, você pode simular novamente a qualquer momento!
          </chakra.p>

          <Spacer h="24px" />

          <Button
            borderRadius="24px"
            isLoading={isLoading}
            onClick={handleCancel}
            w="100%"
          >
            Sim, cancelar
          </Button>

          <Spacer h="16px" />

          <Button
            borderRadius="22px"
            isLoading={isLoading}
            onClick={onClose}
            variant="secondary"
            w="100%"
            _hover={{
              bg: "primary.50",
            }}
          >
            Não
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
