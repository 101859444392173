export class SessionStorage {
  getCurrentStep(): number {
    return parseInt(sessionStorage.getItem("currentStep") || "0");
  }

  getActiveStep(): number {
    return parseInt(sessionStorage.getItem("activeStep") || "0");
  }

  setCurrentStep(newCurrentStep: number, newActiveStep: number): void {
    sessionStorage.setItem("currentStep", newCurrentStep + "");
    sessionStorage.setItem("activeStep", newActiveStep + "");
  }

  setActiveStep(newActiveStep: number) {
    sessionStorage.setItem("activeStep", newActiveStep + "");
  }
}

export const Session = new SessionStorage();
