import { useMemo } from "react";

import { TCarouselItem, Tutorial } from "../../../components";
import { useData } from "../../../stores";
import { useDeviceSelectors } from "react-device-detect";

import {
  allowWithdraw1Android,
  allowWithdraw1Desktop,
  allowWithdraw1Ios,
  allowWithdraw2,
  allowWithdraw3,
  allowWithdraw4,
  allowWithdraw5,
  allowBank1,
  allowBank2,
  allowBank3,
  allowBank4,
  allowBmg5,
  allowBmg6,
  allowBmg7,
  allowSafra5,
  allowSafra6,
  allowSafra7,
  allowC65,
  allowC66,
  allowC67,
  allowFacta5,
  allowFacta6,
  allowFacta7,
  allowBank8,
} from "../../../assets/tutorial";

import {
  AllowWithdraw1,
  AllowWithdraw2,
  AllowWithdraw3,
  AllowWithdraw4,
  AllowWithdraw5,
  AllowBank1,
  AllowBank2,
  AllowBank3,
  AllowBank4,
  AllowBank5,
  AllowBank6,
  AllowBank7,
  AllowBank8,
} from "../../../components";

const mdMarginAdjustment = { base: "-104px", md: "-200px" };

export function AllowWithdrawTutorial() {
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { isDesktop, isIOS } = selectors;
  const data = useData();
  const step9 = useMemo(() => {
    let src1 = allowBmg5;
    let src2 = allowSafra5;
    let src3 = allowC65;
    let src4 = allowFacta5;

    switch (data.simBank) {
      case "bmg":
        return {
          id: "9",
          alt: "hello",
          navMargin: { md: "-100px" },
          src: src1,
          content: <AllowBank5 bankName={`Banco ${data.getSimBank("full")}`} />,
        };
      case "safra":
        return {
          id: "9",
          alt: "hello",
          navMargin: { md: "-100px" },
          src: src2,
          content: <AllowBank5 bankName={`Banco ${data.getSimBank("full")}`} />,
        };
      case "c6":
        return {
          id: "9",
          alt: "hello",
          navMargin: { md: "-100px" },
          src: src3,
          content: <AllowBank5 bankName={`Banco ${data.getSimBank("full")}`} />,
        };
      case "facta":
        return {
          id: "9",
          alt: "hello",
          navMargin: { md: "-100px" },
          src: src4,
          content: <AllowBank5 bankName={`${data.getSimBank("full")}`} />,
        };
    }
  }, [data]);

  const step10 = useMemo(() => {
    let src1 = allowBmg6;
    let src2 = allowSafra6;
    let src3 = allowC66;
    let src4 = allowFacta6;

    switch (data.simBank) {
      case "bmg":
        return {
          id: "10",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src1,
          content: <AllowBank6 />,
        };
      case "safra":
        return {
          id: "10",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src2,
          content: <AllowBank6 />,
        };
      case "c6":
        return {
          id: "10",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src3,
          content: <AllowBank6 />,
        };
      case "facta":
        return {
          id: "10",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src4,
          content: <AllowBank6 />,
        };
    }
  }, [data.simBank]);

  const step11 = useMemo(() => {
    let src1 = allowBmg7;
    let src2 = allowSafra7;
    let src3 = allowC67;
    let src4 = allowFacta7;

    switch (data.simBank) {
      case "bmg":
        return {
          id: "11",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src1,
          content: <AllowBank7 />,
        };
      case "safra":
        return {
          id: "11",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src2,
          content: <AllowBank7 />,
        };
      case "c6":
        return {
          id: "11",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src3,
          content: <AllowBank7 />,
        };
      case "facta":
        return {
          id: "11",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src4,
          content: <AllowBank7 />,
        };
    }
  }, [data.simBank]);

  const items: TCarouselItem[] = useMemo(() => {
    return [
      {
        id: "0",
        alt: "Como baixar o aplicativo do FGTS",
        navMargin: { base: "-45px", md: "-10px" },
        src: isDesktop
          ? allowWithdraw1Desktop
          : isIOS
          ? allowWithdraw1Ios
          : allowWithdraw1Android,
        content: <AllowWithdraw1 />,
      },
      {
        id: "1",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowWithdraw2,
        content: <AllowWithdraw2 />,
      },
      {
        id: "2",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowWithdraw3,
        content: <AllowWithdraw3 />,
      },
      {
        id: "3",
        alt: "hello",
        navMargin: { base: "-80px", md: "-180px" },
        src: allowWithdraw4,
        content: <AllowWithdraw4 />,
      },
      {
        id: "4",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowWithdraw5,
        content: <AllowWithdraw5 />,
      },
      {
        id: "5",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowBank1,
        content: <AllowBank1 />,
      },
      {
        id: "6",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowBank2,
        content: <AllowBank2 />,
      },
      {
        id: "7",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowBank3,
        content: <AllowBank3 />,
      },
      {
        id: "8",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowBank4,
        content: <AllowBank4 />,
      },
      step9,
      step10,
      step11,
      {
        id: "12",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowBank8,
        content: <AllowBank8 />,
      },
    ];
  }, [isDesktop, isIOS, step9, step10, step11]);

  return (
    <Tutorial
      backUrl="/como-optar-saque"
      items={items}
      title={`Optar pelo Saque e autorizar o banco ${data.getSimBank("short")}`}
    />
  );
}
