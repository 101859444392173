import { chakra, Flex } from "@chakra-ui/react";
import { DownloadButton } from "./DownloadButton";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { useDeviceSelectors } from "react-device-detect";

export interface DownloadButtonContainerProps {
  isOpen?: boolean;
}

export function DownloadButtonContainer({
  isOpen,
}: DownloadButtonContainerProps) {
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const { isDesktop, isIOS } = selectors;

  return (
    <Flex alignItems="center" flexDir="column" justifyContent="center" w="100%">
      {isDesktop ? (
        <>
          <chakra.p color="darkgray.500" textStyle="p">
            Google Play (Android)
          </chakra.p>

          <Spacer h="12px" />

          <DownloadButton isIOS={false} isOpen={isOpen} />

          <Spacer h="20px" />

          <chakra.p color="darkgray.500" textStyle="p">
            App Store (iOS)
          </chakra.p>

          <Spacer h="12px" />

          <DownloadButton isIOS={true} isOpen={isOpen} />
        </>
      ) : (
        <DownloadButton isIOS={isIOS} isOpen={isOpen} />
      )}
    </Flex>
  );
}
