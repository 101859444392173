import { BannerLayout, BText, Contact } from "../../components";
import { chakra, Flex } from "@chakra-ui/react";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { useData } from "../../stores";
import { useIsMobile } from "../../utils";

export function InProcessFacta() {
  const isMobile = useIsMobile();
  const data = useData();

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        padding: "0px 24px",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo={true}
    >
      <Flex
        alignItems="center"
        flexDir="column"
        h="100%"
        justifyContent="start"
        maxW="306px"
        w="100%"
      >
        <chakra.h1
          color="primary.500"
          fontSize="1.25rem"
          textStyle="title"
          lineHeight="1.625rem"
        >
          Olá, {data.getFirstName()}! Sua proposta está em andamento.
        </chakra.h1>

        <Spacer h="16px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Em breve você receberá um <BText>SMS</BText> ou{" "}
          <BText>mensagem no WhatsApp</BText> do <BText> Banco Facta</BText>{" "}
          para confirmar sua identidade.
        </chakra.p>

        <Spacer h="16px" />

        <chakra.p color="darkgray.500" textStyle="p">
          São apenas 4 passos para completar o processo:
        </chakra.p>

        <Spacer h="24px" />

        <Recommendation num={1} text="Leia e aceite os termos da proposta" />

        <Spacer h="16px" />

        <Recommendation
          num={2}
          text="Leia e aceite os dados e condições da proposta"
        />

        <Spacer h="16px" />

        <Recommendation
          num={3}
          text=" Tire uma foto do seu documento RG ou CNH"
        />

        <Spacer h="16px" />

        <Recommendation
          num={4}
          text="Fotografe o seu rosto conforme as instruções"
        />

        <Spacer h="24px" />

        <chakra.p color="darkgray.500" textStyle="p">
          Pronto! Após esse processo, você será notificado(a) pelo banco Facta
          em <BText>até 48hs</BText> sobre o andamento da sua proposta.
        </chakra.p>

        <Spacer h="24px" />

        <Contact />
      </Flex>
    </BannerLayout>
  );
}

interface RecommendationProps {
  num: number;
  text: string;
}

function Recommendation({ num, text }: RecommendationProps) {
  return (
    <Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        bg="primary.500"
        borderRadius="50%"
        h="28px"
        minH="28px"
        maxH="28px"
        w="28px"
        minW="28px"
        maxW="28px"
      >
        <chakra.p color="white" fontWeight="bold" textStyle="p">
          {num}
        </chakra.p>
      </Flex>

      <Spacer w="12px" />

      <chakra.p color="darkgray.500" textStyle="p">
        {text}
      </chakra.p>
    </Flex>
  );
}
