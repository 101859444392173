import { useCallback, useEffect } from "react";

import { Session } from "../storage";
import { SubmitHandler } from "react-hook-form";
import { useData } from "../stores";
import { useDefaultForm } from "../utils";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { z } from "zod";

const dadosBancariosSchema = z.object({
  account: z.string().nonempty("Insira o número da sua conta"),
  accountDigit: z.string().nonempty("Insira o dígito da sua conta"),
  accountType: z.string().nonempty("Selecione uma opção"),
  agency: z.string().nonempty("Insira o número da sua agência"),
  //agencyDigit: z.string(), .nonempty("Insira o dígito da sua agência"),
  bank: z.string().nonempty("Selecione seu banco"),
});

export type TDadosBancariosSchema = z.infer<typeof dadosBancariosSchema>;

export function useDadosBancariosForm() {
  const history = useHistory();
  const storeData = useData();
  const { enqueueSnackbar } = useSnackbar();

  const onBack = useCallback(() => {
    Session.setActiveStep(4);
    history.push("/endereco");
  }, [history]);

  const onSubmit: SubmitHandler<TDadosBancariosSchema> = useCallback(
    async (data) => {
      const result = await storeData.saveDadosBancarios(data);

      if (result.response) {
        Session.setCurrentStep(6, 6);
        history.push("/resumo");
      } else {
        enqueueSnackbar(
          "Ocorreu um erro ao tentar gravar os dados, tente novamente mais tarde",
          { variant: "error" }
        );
      }
    },
    [enqueueSnackbar, history, storeData]
  );

  const form = useDefaultForm<TDadosBancariosSchema>({
    schema: dadosBancariosSchema,
    defaultValues: {
      account: storeData.account,
      accountDigit: storeData.accountDigit,
      accountType: storeData.accountType,
      agency: storeData.agency,
      //agencyDigit: storeData.agencyDigit,
      bank: storeData.bank,
    },
  });

  useEffect(() => {
    form.reset({
      account: storeData.account,
      agency: storeData.agency,
      accountType: storeData.accountType,
      bank: storeData.bank,
    });
    // eslint-disable-next-line
  }, []);

  return {
    onBack,
    onSubmit,
    ...form,
  };
}
