import { BText } from "../../../BText";
import { chakra } from "@chakra-ui/react";

export function AllowWithdraw2() {
  return (
    <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
      No app FGTS, clique em <BText>Entrar no aplicativo</BText>.
    </chakra.p>
  );
}
