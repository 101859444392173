import { useCallback, useEffect, useState } from "react";

import cepPromise from "cep-promise";
import { cepToStore, useDefaultForm } from "../utils";
import { Session } from "../storage";
import { SubmitHandler } from "react-hook-form";
import { useData } from "../stores";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { z } from "zod";

const enderecoSchema = z.object({
  address: z.string().nonempty({ message: "Insira seu endereço" }),
  cep: z
    .string()
    .nonempty("Insira seu CEP")
    .length(9, "CEP inválido. Verifique os dígitos inseridos"),
  city: z.string().nonempty("Insira sua cidade"),
  complement: z.string().nullable(),
  neighborhood: z.string().nonempty("Insira seu bairro"),
  num: z.string().nonempty("Insira o número do seu endereço"),
  state: z.string().nonempty("Insira seu estado"),
});

export type TEnderecoSchema = z.infer<typeof enderecoSchema>;

export function useEnderecoForm() {
  const history = useHistory();
  const storeData = useData();
  const { enqueueSnackbar } = useSnackbar();

  const [disableAddress, setDisableAddress] = useState(true);
  const [disableNeighborhood, setDisableNeighborhood] = useState(true);
  const [isCepLoading, setIsCepLoading] = useState(false);

  const onBack = useCallback(() => {
    Session.setActiveStep(3);
    history.push("/dadospessoais");
  }, [history]);

  const onSubmit: SubmitHandler<TEnderecoSchema> = useCallback(
    async (data) => {
      const result = await storeData.saveEndereco(data);

      if (result.response) {
        Session.setCurrentStep(5, 5);
        history.push("/dadosbancarios");
      } else {
        enqueueSnackbar(
          "Ocorreu um erro ao tentar gravar os dados, tente novamente mais tarde",
          { variant: "error" }
        );
      }
    },
    [enqueueSnackbar, history, storeData]
  );

  const form = useDefaultForm<TEnderecoSchema>({
    schema: enderecoSchema,
    defaultValues: {
      address: storeData.address,
      cep: cepToStore(storeData.cep),
      city: storeData.city,
      complement: storeData.complement,
      neighborhood: storeData.neighborhood,
      num: storeData.num,
      state: storeData.state,
    },
  });

  const { setError, setValue, trigger, watch } = form;
  const cep = watch("cep");

  const checkCep = useCallback(async () => {
    if (cep.length === 9) {
      try {
        setIsCepLoading(true);

        const addr = await cepPromise(cep.replace("-", ""));

        setValue("address", addr.street.split(" - de ")?.[0] || addr.street);
        setValue("city", addr.city);
        setValue("neighborhood", addr.neighborhood);
        setValue("state", addr.state);
        trigger("cep");

        if (!addr.street) {
          setDisableAddress(false);
        }

        if (!addr.neighborhood) {
          setDisableNeighborhood(false);
        }
      } catch (error) {
        setError("cep", {
          type: "manual",
          message: "CEP inválido. Verifique os dígitos inseridos.",
        });
      } finally {
        setIsCepLoading(false);
      }
    } else {
      setValue("address", "");
      setValue("city", "");
      setValue("neighborhood", "");
      setValue("state", "");
      setDisableAddress(true);
      setDisableNeighborhood(true);
    }
  }, [cep, setError, setValue, trigger]);

  useEffect(() => {
    const checkCep2 = async () => {
      if (cep.length === 8 || cep.length === 9) {
        try {
          setIsCepLoading(true);

          const addr = await cepPromise(cep.replace("-", ""));

          setValue("address", addr.street);
          setValue("city", addr.city);
          setValue("neighborhood", addr.neighborhood);
          setValue("state", addr.state);
          trigger("cep");

          if (!addr.street) {
            setDisableAddress(false);
          }

          if (!addr.neighborhood) {
            setDisableNeighborhood(false);
          }
        } catch (error) {
          setError("cep", {
            type: "manual",
            message: "CEP inválido. Verifique os dígitos inseridos.",
          });
        } finally {
          setIsCepLoading(false);
        }
      } else {
        setValue("address", "");
        setValue("city", "");
        setValue("neighborhood", "");
        setValue("state", "");
        setDisableAddress(true);
        setDisableNeighborhood(true);
      }
    };

    checkCep2();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkCep();
  }, [checkCep]);

  return {
    disableAddress,
    disableNeighborhood,
    isCepLoading,
    onBack,
    onSubmit,
    ...form,
  };
}
