import React from "react";
import ReactDOM from "react-dom";

import { CookiesProvider } from "react-cookie";
import { Router } from "./pages/Router";
import { StylesProvider } from "./components";
import TagManager from "react-gtm-module";

import "focus-visible/dist/focus-visible";
import "./index.css";

const tagManagerArgs = {
  gtmId: "GTM-MNTN4HT",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <StylesProvider>
        <div style={{ display: "none" }}>V 1.0.3</div>
        <Router />
      </StylesProvider>
    </CookiesProvider>
  </React.StrictMode>,

  document.getElementById("root")
);
