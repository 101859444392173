import { brlFormat } from "../utils";
import { chakra, Flex } from "@chakra-ui/react";
import { Spacer } from "@eqg/chakra-lib.components.all";
export interface OptionCardProps {
  value: number;
  startYear: number;
  endYear: number;
  helperText?: React.ReactNode;
}

export function OptionCard({
  value,
  startYear,
  endYear,
  helperText,
}: OptionCardProps) {
  return (
    <Flex flexDir="column">
      <chakra.h4 color="success.800" textStyle="moneyText">
        {brlFormat.format(value)}
      </chakra.h4>
      <Spacer h="4px" />
      <chakra.p textStyle="p" fontSize="0.875rem">
        <chakra.span fontWeight="bold" fontSize="1rem">
          {endYear - startYear + 1} anos
        </chakra.span>{" "}
        ({startYear} - {endYear})
      </chakra.p>
      {helperText && (
        <>
          <Spacer h="8px" />
          {helperText}
        </>
      )}
    </Flex>
  );
}
