import { useCallback } from "react";

import { Session } from "../storage";
import { SubmitHandler } from "react-hook-form";
import { useData } from "../stores";
import { useDefaultForm } from "../utils";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import { z } from "zod";

const finalFeedbackSchema = z.object({
  comment: z.string(),
  rating: z.number().nullable(),
});

export type TFinalFeedbackSchema = z.infer<typeof finalFeedbackSchema>;

export function useFinalFeedbackForm() {
  const history = useHistory();
  const storeData = useData();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<TFinalFeedbackSchema> = useCallback(
    async (data) => {
      if (data.rating || data.comment) {
        const result = await storeData.saveFeedback(data);

        if (result.error) {
          enqueueSnackbar(
            "Ocorreu um erro ao tentar gravar os dados, tente novamente mais tarde",
            { variant: "error" }
          );
        } else {
          Session.setCurrentStep(110, 110);
          history.push("/sucesso");
        }
      } else {
        Session.setCurrentStep(110, 110);
        history.push("/sucesso");
      }
    },
    [enqueueSnackbar, history, storeData]
  );

  return {
    firstName: storeData.getFirstName(),
    onSubmit,
    ...useDefaultForm<TFinalFeedbackSchema>({
      schema: finalFeedbackSchema,
      defaultValues: {
        comment: storeData.comment,
        rating: storeData.rating,
      },
    }),
  };
}
