import { useCallback, useEffect, useState } from "react";

import { defaultValues, useData } from "../stores";
import { Session } from "../storage";
import { SubmitHandler } from "react-hook-form";
import { useDefaultForm, validateOflegalage } from "../utils";
import { useCookies } from "react-cookie";
import { useSnackbar } from "notistack";
import { useQuery } from "../utils/use-query";
import { z } from "zod";

import {
  validateBirthdate,
  validateCpf,
  validateEmail,
  validatePhone,
  validateNameCharacters,
  validateFullName,
} from "@eqg/validation-lib.validations.all";
import axios from "axios";

const identificacaoSchema = z.object({
  birthdate: z
    .string()
    .nonempty("Insira sua data de nascimento")
    .refine(
      (date) => validateBirthdate(date),
      "Data de nascimento inválida. Verifique os dígitos inseridos."
    )
    .refine(
      (date: any) => {
        if (date.valid === false) {
          return false;
        } else {
          return validateOflegalage(date);
        }
      }, //validateOflegalage(date)},
      "Você precisa ter 18 anos ou mais para continuar"
    ),
  cpf: z
    .string()
    .nonempty({ message: "Insira seu CPF" })
    .refine(
      (cpf) => validateCpf(cpf),
      "CPF inválido. Verifique os dígitos inseridos."
    ),
  email: z
    .string()
    .transform((val) => val.trim())
    .refine(
      (email) => (!email ? true : validateEmail(email)),
      "Insira um email válido"
    ),
  fullName: z
    .string()
    .nonempty({ message: "Insira o Primeiro e Último Nome" })
    .transform((val) => val.trim())
    .refine(
      (fullName) => validateNameCharacters(fullName),
      "Precisa ter apenas letras e espaços"
    )
    .refine(
      (fullName) => validateFullName(fullName),
      "Insira o Primeiro e Último Nome"
    )
    .refine(
      (fullName) => fullName.length > 2,
      "Insira o Primeiro e Último Nome"
    ),
  optin: z
    .boolean()
    .refine((optin) => optin, "Você precisa aceitar para continuar"),
  whatsapp: z
    .string()
    .nonempty({ message: "Insira seu número do WhatsApp" })
    .length(15, "Número do WhatsApp inválido. Verifique os dígitos inseridos.")
    .refine(
      (whatsapp) => validatePhone(whatsapp),
      "Número do WhatsApp inválido. Verifique os dígitos inseridos."
    ),
});

const identificacaoSchema2 = z.object({
  birthdate: z
    .string()
    .nonempty("Insira sua data de nascimento")
    .refine(
      (date) => validateBirthdate(date),
      "Data de nascimento inválida. Verifique os dígitos inseridos."
    )
    .refine(
      (date: any) => {
        if (date.valid === false) {
          return false;
        } else {
          return validateOflegalage(date);
        }
      }, //validateOflegalage(date)},
      "Você precisa ter 18 anos ou mais para continuar"
    ),
  cpf: z
    .string()
    .nonempty({ message: "Insira seu CPF" })
    .refine(
      (cpf) => validateCpf(cpf),
      "CPF inválido. Verifique os dígitos inseridos."
    ),
  email: z
    .string()
    .transform((val) => val.trim())
    .refine(
      (email) => (!email ? true : validateEmail(email)),
      "Insira um email válido"
    ),
  fullName: z
    .string()
    .nonempty({ message: "Insira o seu nome completo" })
    .transform((val) => val.trim())
    .refine(
      (fullName) => validateNameCharacters(fullName),
      "Precisa ter apenas letras e espaços"
    )
    .refine(
      (fullName) => validateFullName(fullName),
      "Insira o seu nome completo"
    )
    .refine((fullName) => fullName.length > 2, "Insira o seu nome completo"),
  optin: z
    .boolean()
    .refine((optin) => optin, "Você precisa aceitar para continuar"),
  whatsapp: z
    .string()
    .nonempty({ message: "Insira seu número do WhatsApp" })
    .length(15, "Número do WhatsApp inválido. Verifique os dígitos inseridos.")
    .refine(
      (whatsapp) => validatePhone(whatsapp),
      "Número do WhatsApp inválido. Verifique os dígitos inseridos."
    ),
});

export type TIdentificacaoSchema = z.infer<typeof identificacaoSchema>;

export function useIdentificacaoForm() {
  const storeData = useData();
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery();

  const [cookies] = useCookies(["__gtm_campaign_url"]);

  function convertCookie(cookieUrl: string) {
    const rawParams = cookieUrl?.split("?")?.[1]?.split("&");

    const wantedParams = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
      "utm_id",
      "gclid",
      "fbclid",
      "aff_click_id"
    ];

    const cleanParams: { [key: string]: string } = {};

    if (rawParams) {
      for (const rawParam of rawParams) {
        const [key, value] = rawParam.split("=");
        // eslint-disable-next-line eqeqeq
        if (key == 'aff_click_id') {
          axios.get(`https://api.vsboon.com.br/reward/delivery/savvi?aff_click_id=${value}&action_type=Inapp`)
            .then(() => {
           
            })
            .catch(() => {
           
            })
        }
        if (key && value && wantedParams.includes(key)) {
          cleanParams[key] = value;
        }
      }

      return cleanParams;
    }

    return {};
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit: SubmitHandler<TIdentificacaoSchema> = useCallback(
    async (data) => {
      const cookieParams = convertCookie(cookies.__gtm_campaign_url);

      const result = await storeData.saveIdentificacao(
        data,
        query.get("codigo"),
        cookieParams
      );

      if (!result.response) {
        const errorRes = result.errorRes;

        if (errorRes?.cpf) {
          enqueueSnackbar(
            "Não foi possível simular. Já existe uma proposta em andamento com esse CPF",
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(
            "Ocorreu um erro ao tentar gravar os dados, tente novamente mais tarde",
            { variant: "error" }
          );
        }
      } else {
        setIsModalOpen(true);
      }
    },
    [enqueueSnackbar, storeData, query, cookies.__gtm_campaign_url]
  );

  /* Reset step when the home page loads */
  useEffect(() => {
    storeData.reset();
    Session.setCurrentStep(1, 1);
    // eslint-disable-next-line
  }, []);
  const dataName = useData();
  const condition = dataName.simBank === "c6";

  return {
    isModalOpen,
    setIsModalOpen,
    onSubmit,
    ...useDefaultForm<TIdentificacaoSchema>({
      schema: condition ? identificacaoSchema : identificacaoSchema2,
      // schema: identificacaoSchema,
      defaultValues: {
        fullName: defaultValues.fullName,
        cpf: defaultValues.cpf,
        email: defaultValues.email,
        optin: defaultValues.optin,
        whatsapp: defaultValues.whatsapp,
      },
    }),
  };
}
