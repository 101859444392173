import { useMemo } from "react";

import { AllowBank0 } from "../../../components/carousel/items/allow-bank/allow-bank-0";
import { AllowBank01 } from "../../../components/carousel/items/allow-bank/allow-bank-01";
import { TCarouselItem, Tutorial } from "../../../components";
import { useData } from "../../../stores";

import {
  AllowBank1,
  AllowBank2,
  AllowBank3,
  AllowBank4,
  AllowBank5,
  AllowBank6,
  AllowBank7,
  AllowBank8,
} from "../../../components";

import {
  allowBank1,
  allowBank2,
  allowBank3,
  allowBank4,
  allowBmg5,
  allowBmg6,
  allowBmg7,
  allowC65,
  allowC66,
  allowC67,
  allowFacta5,
  allowFacta6,
  allowFacta7,
  allowSafra5,
  allowSafra6,
  allowSafra7,
  allowBank8,
  allowWithdraw2,
  allowWithdraw3,
} from "../../../assets/tutorial";

const smMarginAdjustment = "-45px";
const mdMarginAdjustment = { base: "-104px", md: "-200px" };

export function AllowBankTutorial() {
  const data = useData();
  const phone = data.whatsapp
  .replace("(", "")
  .replace(")", "")
  .replace(" ", "")
  .replace("-", "");
//@ts-ignore
window.dataLayer.push({
  event: "fgts-cpf-valido",
  email: data.email,
  phone_number: `+55${phone}`,
  "address.first_name": data.getFirstName(),
  "address.last_name": data.getLastName(),
  "address.country": "Br",
});
//@ts-ignore
window.dataLayer.push({
  event: "fgts-cpf-nao-valido",
  email: data.email,
  phone_number: `+55${phone}`,
  "address.first_name": data.getFirstName(),
  "address.last_name": data.getLastName(),
  "address.country": "Br",
});

  const step4 = useMemo(() => {
    let src1 = allowBmg5;
    let src2 = allowSafra5;
    let src3 = allowC65;
    let src4 = allowFacta5;
    
    switch (data.simBank) {
      case "bmg":
        return {
          id: "4",
          alt: "hello",
          navMargin: { md: "-100px" },
          src: src1,
          content: <AllowBank5 bankName={`Banco ${data.getSimBank("full")}`} />,
        };
      case "safra":
        return {
          id: "4",
          alt: "hello",
          navMargin: { md: "-100px" },
          src: src2,
          content: <AllowBank5 bankName={`Banco ${data.getSimBank("full")}`} />,
        };
      case "c6":
        return {
          id: "4",
          alt: "hello",
          navMargin: { md: "-100px" },
          src: src3,
          content: <AllowBank5 bankName={`Banco ${data.getSimBank("full")}`} />,
        };
      case "facta":
        return {
          id: "4",
          alt: "hello",
          navMargin: { md: "-100px" },
          src: src4,
          content: <AllowBank5 bankName={`${data.getSimBank("full")}`} />,
        };
    }
  }, [data]);

  const step5 = useMemo(() => {
    let src1 = allowBmg6;
    let src2 = allowSafra6;
    let src3 = allowC66;
    let src4 = allowFacta6;

    switch (data.simBank) {
      case "bmg":
        return {
          id: "5",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src1,
          content: <AllowBank6 />,
        };
      case "safra":
        return {
          id: "5",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src2,
          content: <AllowBank6 />,
        };
      case "c6":
        return {
          id: "5",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src3,
          content: <AllowBank6 />,
        };
      case "facta":
        return {
          id: "5",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src4,
          content: <AllowBank6 />,
        };
    }
  }, [data.simBank]);

  const step6 = useMemo(() => {
    let src1 = allowBmg7;
    let src2 = allowSafra7;
    let src3 = allowC67;
    let src4 = allowFacta7;

    switch (data.simBank) {
      case "bmg":
        return {
          id: "6",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src1,
          content: <AllowBank7 />,
        };
      case "safra":
        return {
          id: "6",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src2,
          content: <AllowBank7 />,
        };
      case "c6":
        return {
          id: "6",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src3,
          content: <AllowBank7 />,
        };
      case "facta":
        return {
          id: "6",
          alt: "hello",
          navMargin: mdMarginAdjustment,
          src: src4,
          content: <AllowBank7 />,
        };
    }
  }, [data.simBank]);

  const items: TCarouselItem[] = useMemo(() => {
    return [
      {
        id: "-2",
        alt: "hello",
        navMargin: { base: smMarginAdjustment, md: "-10px" },
        src: allowWithdraw2,
        content: <AllowBank0 />,
      },
      {
        id: "-1",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowWithdraw3,
        content: <AllowBank01 />,
      },
      {
        id: "0",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowBank1,
        content: <AllowBank1 />,
      },
      {
        id: "1",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowBank2,
        content: <AllowBank2 />,
      },
      {
        id: "2",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowBank3,
        content: <AllowBank3 />,
      },
      {
        id: "3",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowBank4,
        content: <AllowBank4 />,
      },

      step4,
      step5,
      step6,
      {
        id: "7",
        alt: "hello",
        navMargin: mdMarginAdjustment,
        src: allowBank8,
        content: <AllowBank8 />,
      },
    ];
  }, [step4, step5, step6]);

  return (
    <Tutorial
      backUrl="/autorizar-banco"
      items={items}
      title={`Autorizar o banco ${data.getSimBank(
        "short"
      )} a consultar seu saldo`}
    />
  );
}
