import { useMemo } from "react";

import { BannerLayout } from "./banner/BannerLayout";
import { chakra, Flex, Spacer as CSpacer } from "@chakra-ui/react";
import { SecureEnvMobile } from "./SecureEnvMobile";
import { SkipButton } from "./SkipButton";
import { StepperLayout } from "./stepper";
import { useIsMobile } from "../utils";

import {
  Button,
  Form,
  Spacer,
  SubmitButton,
} from "@eqg/chakra-lib.components.all";

export interface FormLayoutProps {
  backLabel?: string;
  children: React.ReactNode;
  description: string | React.ReactNode;
  isBanner?: boolean;
  isLoading?: boolean;
  isSkipable?: boolean;
  nextLabel: string;
  noBack?: boolean;
  onBack?: () => void;
  onSkip?: () => void;
  onSubmit: React.FormEventHandler<HTMLDivElement>;
  skipLabel?: string;
  title: string;
}

export function FormLayout({
  backLabel,
  children,
  description,
  isBanner,
  isLoading,
  isSkipable,
  nextLabel,
  noBack,
  onBack,
  onSkip,
  onSubmit,
  skipLabel,
  title,
}: FormLayoutProps) {
  const isMobile = useIsMobile();

  const BaseLayout = useMemo(
    () => (isBanner ? BannerLayout : StepperLayout),
    [isBanner]
  );

  return (
    <BaseLayout>
      <Flex
        bg="lightgray.200"
        flex={1}
        justifyContent="center"
        overflow="auto"
        padding="0px 24px"
      >
        <Form
          flexDir="column"
          marginTop={{ base: "20px", md: "64px" }}
          onSubmit={onSubmit}
          maxW="306px"
          w="100%"
        >
          {!isMobile && (
            <>
              <chakra.h2 color="primary.500" textStyle="smallTitle">
                {title}
              </chakra.h2>
              <Spacer h="0.5rem" />
            </>
          )}

          <chakra.p color="darkgray.500" textStyle="p">
            {description}
          </chakra.p>

          <Spacer h="16px" />

          {children}

          <Spacer h="32px" />

          <Flex justifyContent="space-between">
            {!noBack && (
              <>
                <Button
                  _hover={{
                    bg: "primary.50",
                  }}
                  borderRadius="24px"
                  isLoading={isLoading}
                  onClick={onBack}
                  variant="secondary"
                  w="50%"
                >
                  {backLabel}
                </Button>

                <Spacer w="16px" />
              </>
            )}

            <SubmitButton
              borderRadius="24px"
              isLoading={isLoading}
              w={noBack ? "100%" : "50%"}
            >
              {nextLabel}
            </SubmitButton>
          </Flex>

          <Spacer h="20px" />

          <SkipButton />

          {isMobile && <SecureEnvMobile />}
          <CSpacer h="32px" minH="32px" />
        </Form>
      </Flex>
    </BaseLayout>
  );
}
