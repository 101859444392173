import { chakra, Flex } from "@chakra-ui/react";

export function Contact() {
  return (
    <Flex alignItems="center" flexDir="column" w="100%">
      <chakra.p color="darkgray.100" fontSize="0.875rem" textStyle="p">
        Central de Relacionamento
      </chakra.p>

      {/* <Spacer h="8px" /> */}

      <chakra.p
        color="darkgray.500"
        fontSize="0.875rem"
        fontWeight="semibold"
        textStyle="p"
      >
        0800 1000 123
      </chakra.p>

      <chakra.p
        color="darkgray.500"
        fontSize="0.875rem"
        fontWeight="semibold"
        textStyle="p"
      >
        falecom@euquerograna.com.br
      </chakra.p>
    </Flex>
  );
}
