import { BannerLayout, BText, Contact } from "../../components";
import { chakra, Flex } from "@chakra-ui/react";
import { ReactComponent as ImgCalendar } from "../../assets/img-calendario.svg";
import { Spacer } from "@eqg/chakra-lib.all";
import { useData } from "../../stores";
import { useIsMobile } from "../../utils";
import { useMemo } from "react";
import { TMsgErr } from "../../services";

export function OutOfDate() {
  const isMobile = useIsMobile();
  const data = useData();

  const date = useMemo(() => {
    const d = data.msgErr as TMsgErr;
    if (d) {
      return d.descricao
        ?.match(/(\d{4}([.\-/ ])\d{2}\2\d{2}|\d{2}([.\-/ ])\d{2}\3\d{4})/)?.[0]
        .replace("-", "/")
        .replace("-", "/");
    }
    return "";
  }, [data]);

  console.log(data.msgErr);

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        padding: "0px 24px",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo={true}
    >
      <Flex
        alignItems="center"
        flexDir="column"
        h="100%"
        justifyContent="center"
        maxW="306px"
        w="100%"
      >
        <ImgCalendar style={{ height: "126px", width: "126px" }} />

        <Spacer h="16px" />

        <chakra.h1
          color="primary.500"
          fontSize="1.5rem"
          textAlign="center"
          textStyle="title"
          lineHeight="1.95rem"
        >
          Olá, {data.getFirstName()}. Seu Saque Aniversário FGTS será liberado
          no dia {date}.
        </chakra.h1>

        <Spacer h="12px" />

        <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
          Após essa data, entraremos em contato através do WhatsApp, SMS ou por
          ligação com o <BText>DDD 19</BText> para dar andamento no seu saque.
          Até breve!
        </chakra.p>

        <Spacer h="32px" />

        <Contact />
      </Flex>
    </BannerLayout>
  );
}
