import { BannerLayout } from "../../components";
import { chakra } from "@chakra-ui/react";
import { InputControl } from "../../components";
import { SecureEnvMobile } from "../../components";
import { SimulationModal } from "../../components/SimulationModal";
import { useIdentificacaoForm } from "../../schemas";
import { useIsMobile } from "../../utils";
import { useData } from "../../stores";

import {
  TextField,
  MaskedField,
  Form,
  Checkbox,
  Spacer,
  SubmitButton,
} from "@eqg/chakra-lib.all";

export function IdentificacaoStep() {
  const isMobile = useIsMobile();

  const {
    formState: { errors, isSubmitting },
    isModalOpen,
    setIsModalOpen,
    register,
    handleSubmit,
    onSubmit,
  } = useIdentificacaoForm();

  const data = useData();
  const condition = data.simBank === "c6";
  const currentlabel = condition ? "Primeiro e Último Nome" : "Nome completo";
  const maxlength = condition ? 35 : 999;

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        paddingTop: isMobile ? "24px" : "64px",
      }}
    >
      <Form
        flexDir="column"
        onSubmit={handleSubmit(onSubmit)}
        maxW="306px"
        w="100%"
      >
        {!isMobile && (
          <>
            <chakra.h4
              color="primary.500"
              textAlign="center"
              textStyle="h4"
              fontSize="1.5rem"
              fontWeight="bold"
            >
              Simule grátis!
            </chakra.h4>

            <Spacer h="24px" />
          </>
        )}

        <InputControl
          error={errors.fullName?.message}
          label={currentlabel}
          register={register("fullName")}
        >
          <TextField maxLength={maxlength} />
        </InputControl>

        <Spacer h="24px" />

        <InputControl
          error={errors.cpf?.message}
          label="CPF"
          register={register("cpf")}
        >
          <MaskedField
            keyboard="numeric"
            mask="999.999.999-99"
            placeholder="000.000.000-00"
          />
        </InputControl>

        <Spacer h="24px" />

        <InputControl
          error={errors.birthdate?.message}
          label="Data de nascimento"
          register={register("birthdate")}
        >
          <MaskedField
            keyboard="numeric"
            mask="99/99/9999"
            placeholder="dd/mm/aaaa"
          />
        </InputControl>

        <Spacer h="24px" />

        <InputControl
          error={errors.whatsapp?.message}
          label="WhatsApp"
          labelHelper=" (com DDD)"
          register={register("whatsapp")}
        >
          <MaskedField
            keyboard="tel"
            mask="(99) 99999-9999"
            placeholder="(00) 00000-0000"
          />
        </InputControl>

        <Spacer h="24px" />

        <InputControl
          error={errors.email?.message}
          label="Email"
          labelHelper=" (opcional)"
          register={register("email")}
          helperText="O email é nossa melhor forma de comunicação."
        >
          <TextField placeholder="email@dominio.com" />
        </InputControl>

        <Spacer h="24px" />

        <InputControl
          error={errors.optin?.message}
          register={register("optin")}
        >
          <Checkbox boxPosition="top" color="darkgray.700" fontWeight="medium">
            Aceito receber informações pelos canais de relacionamento (SMS,
            telefone, WhatsApp e email)
          </Checkbox>
        </InputControl>

        <Spacer h="32px" />

        <SubmitButton borderRadius="24px" isLoading={isSubmitting}>
          Continuar
        </SubmitButton>

        {isMobile && (
          <>
            <Spacer h="20px" />
            <SecureEnvMobile />
          </>
        )}

        <SimulationModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      </Form>
    </BannerLayout>
  );
}
