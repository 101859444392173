import { chakra, Flex, FlexProps, Spacer } from "@chakra-ui/react";

interface FooterProps extends FlexProps {}

export function Footer(props: FooterProps) {
  return (
    <Flex alignItems="center" direction="column" {...props}>
      <chakra.h1
        color="white"
        fontFamily="primary"
        fontSize="2rem"
        textAlign="center"
        textStyle="title"
        textShadow="0px 2px 4px #35240E1A;"
      >
        Antecipação FGTS
      </chakra.h1>

      <Spacer h="8px" maxH="8px" minH="8px" />

      <chakra.h6
        color="white"
        textAlign="center"
        textShadow="0px 2px 4px #35240E1A;"
        textStyle="subtitle"
        w="318px"
        maxW="100%"
      >
        O empréstimo que já nasce pago
      </chakra.h6>
    </Flex>
  );
}
