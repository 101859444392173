import { useCallback, useMemo } from "react";

import { Button, Spacer } from "@eqg/chakra-lib.components.all";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { Flex, FlexProps, Progress } from "@chakra-ui/react";
import { useHistory } from "react-router";

export interface CarouselNavProps extends FlexProps {
  active: number;
  numOfItems: number;
  onBack: () => void;
  onNext: () => void;
}

export function CarouselNav({
  active,
  numOfItems,
  onBack,
  onNext,
  ...rest
}: CarouselNavProps) {
  const history = useHistory();
  const handleSkip = useCallback(() => {
    history.push("/simule");
  }, [history]);

  const progress = useMemo(
    () => ((active + 1) / numOfItems) * 100,
    [active, numOfItems]
  );

  return (
    <>
      <Flex alignItems="center" w="100%" {...rest}>
        <Button
          borderRadius="50%"
          h="44px"
          Icon={<FiArrowLeft fontSize="26px" />}
          mr="12px"
          onClick={onBack}
          variant="secondary"
          w="44px"
        />

        <Progress
          bg="lightgray.500"
          borderRadius="8px"
          colorScheme="primary"
          h="6px"
          value={progress}
          flex={1}
        />

        <Button
          borderRadius="50%"
          h="44px"
          Icon={<FiArrowRight fontSize="26px" />}
          ml="12px"
          onClick={onNext}
          w="44px"
        />
      </Flex>

      <Spacer h="16px" />

      <Button onClick={handleSkip} variant="link">
        Pular passos
      </Button>
    </>
  );
}
