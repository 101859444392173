import { BText } from "../../../BText";
import { chakra } from "@chakra-ui/react";

export function AllowBank6() {
  return (
    <>
      <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
        Clique em <BText>Continuar</BText>.
      </chakra.p>
    </>
  );
}
