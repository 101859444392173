import { chakra, Flex, FlexProps } from "@chakra-ui/react";
import { ReactComponent as EqgLogoWhite } from "../../assets/logo-eqg-white.svg";

interface BannerMobileProps extends FlexProps {
  title: string;
}

export function BannerMobileCustomTitle({ title, ...rest }: BannerMobileProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg="transparent linear-gradient(90deg, #00A1D0 0%, #00B3D5 33%, #00D2C5 67%, #16E0B0 100%) 0% 0% no-repeat padding-box;"
      h="72px"
      maxH="72px"
      minH="72px"
      padding="0px 24px"
      w="100%"
      {...rest}
    >
      <Flex
        alignItems="center"
        h="100%"
        minH="100%"
        maxH="100%"
        justifyContent="space-between"
        w="100%"
        maxW="306px"
      >
        <chakra.h1
          color="white"
          flex={1}
          fontFamily="primary"
          fontSize="1rem"
          fontWeight="bold"
          lineHeight="1.375rem"
          mr="12px"
          textAlign="start"
          textShadow="0px 2px 4px #35240E1F;"
        >
          {title}
        </chakra.h1>

        <EqgLogoWhite
          style={{
            filter: "drop-shadow(0px 2px 4px #7090B059)",
            transform: "scale(1.2)",
            width: "60px",
          }}
        />
      </Flex>
    </Flex>
  );
}
