import { BText } from "../../../BText";
import { chakra } from "@chakra-ui/react";

export function AllowWithdraw3() {
  return (
    <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
      Entre no aplicativo com seu <BText>CPF e senha</BText> cadastrados.
    </chakra.p>
  );
}
