import { chakra, Flex, FlexProps, useTheme } from "@chakra-ui/react";
import { HiOutlineLockClosed } from "react-icons/hi";
import { Spacer } from "@eqg/chakra-lib.components.all";

export function SecureEnvMobile(props: FlexProps) {
  const theme = useTheme();

  return (
    <Flex alignItems="center" justifyContent="center" {...props}>
      <HiOutlineLockClosed
        color={theme.colors.darkgray[200]}
        style={{
          color: theme.colors.darkgray[200],
          fontSize: "1rem",
          marginTop: "-0.125rem",
        }}
      />

      <Spacer w="4px" />

      <chakra.p
        color="darkgray.200"
        fontFamily="primary"
        fontSize="0.875rem"
        fontWeight="medium"
        lineHeight="1.3125rem"
      >
        Você está em um ambiente seguro.
      </chakra.p>
    </Flex>
  );
}
