import { Route, Redirect, RouteProps } from "react-router";
import { Session } from "../storage/session-storage";

interface StepRouteProps extends RouteProps {
  /**
   * Routes number description:
   *
   * - From 1 to 99: Intermediate steps
   * - From 100 to 199: Feedback steps
   *
   * - 110 Is the success page
   * - 109 is the feedback page
   *
   * - 120, 121 and 122 are the feedbacks that ask info
   * - 130 to 140 other feedbacks
   */
  num: number;
}

export function StepRoute({ num, ...rest }: StepRouteProps) {
  const currentStep = Session.getCurrentStep();

  if (!currentStep || num === 1) {
    return <Route {...rest} />;
  }

  // Intermediate steps has the previous step as requirement
  if (num >= 1 && num <= 99 && num > currentStep) {
    return <Redirect to="/" />;
  }

  // Success page, cannot go back
  if (num !== 1 && num !== 110 && currentStep === 110) {
    return <Redirect to="/" />;
  }

  // Final feedback page, cannot go back
  if (num !== 1 && num !== 109 && currentStep === 109) {
    return <Redirect to="/" />;
  }

  // Feedbacks that ask info
  if (num !== 1 && num >= 120 && num <= 129 && num > currentStep) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} />;
}
