import {
  InputControl as EqgInputControl,
  InputControlProps as EqgInputControlProps,
} from "@eqg/chakra-lib.all";

export function InputControl({ children, ...rest }: EqgInputControlProps) {
  return (
    <EqgInputControl
      helperTextProps={{ fontWeight: "medium" }}
      labelProps={{ fontWeight: "bold" }}
      {...rest}
    >
      {children}
    </EqgInputControl>
  );
}
