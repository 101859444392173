import React from "react";

import { Flex } from "@chakra-ui/react";
import { HiCheck } from "react-icons/hi";

export interface StepIconProps {
  icon: React.ReactElement;
  state: "completed" | "current" | "next";
}

export function StepIcon({ icon, state }: StepIconProps) {
  return (
    <Flex
      alignItems="center"
      bg={state === "completed" ? "white" : "transparent"}
      border="3px solid white"
      borderColor={state === "next" ? "#ffffffbb" : "white"}
      borderRadius="50%"
      h="48px"
      justifyContent="center"
      w="48px"
    >
      {state === "completed" ? (
        <HiCheck style={{ color: "#00A1D0", fontSize: "34px" }} />
      ) : (
        <>
          {React.createElement(icon.type, {
            ...icon.props,
            style: { color: state === "current" ? "white" : "#ffffffbb" },
          })}
        </>
      )}
    </Flex>
  );
}
