import { BannerLayout, BText, Contact } from "../../components";
import { chakra, Flex } from "@chakra-ui/react";
import { ReactComponent as ImgAtendente } from "../../assets/img-atendente.svg";
import { Spacer } from "@eqg/chakra-lib.all";
import { useIsMobile } from "../../utils";

export function OutOfService() {
  const isMobile = useIsMobile();

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        padding: "0px 24px",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo={true}
    >
      <Flex
        alignItems="center"
        flexDir="column"
        h="100%"
        justifyContent="center"
        maxW="306px"
        w="100%"
      >
        <ImgAtendente style={{ height: "126px", width: "126px" }} />

        <Spacer h="12px" />

        <chakra.h1
          color="primary.500"
          fontSize="1.5rem"
          textAlign="center"
          textStyle="title"
          lineHeight="1.95rem"
        >
          A Caixa Econômica Federal está fora do horário de atendimento
        </chakra.h1>

        <Spacer h="12px" />

        <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
          Em breve entraremos em contato através do WhatsApp, SMS ou por ligação
          com o <BText>DDD 19</BText> para dar andamento no seu saque. Até logo!
        </chakra.p>

        <Spacer h="8px" />

        <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
          <BText>Horário de atendimento</BText>
        </chakra.p>

        <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
          Segunda a sexta, das 7h às 22h
        </chakra.p>

        <Spacer h="32px" />

        <Contact />
      </Flex>
    </BannerLayout>
  );
}
