import { ChakraProvider } from "@chakra-ui/react";
import { SnackbarProvider } from "notistack";
import { theme } from "../themes";
interface StylesProviderProps {
  children: React.ReactNode;
}

export function StylesProvider({ children }: StylesProviderProps) {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
    >
      <ChakraProvider resetCSS theme={theme}>
        {children}
      </ChakraProvider>
    </SnackbarProvider>
  );
}
