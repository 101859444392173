import { BText } from "../../../BText";
import { chakra } from "@chakra-ui/react";
import { DownloadButtonContainer } from "../../DownloadButtonContainer";
import { Spacer } from "@eqg/chakra-lib.components.all";

export function AllowBank0() {
  return (
    <>
      <DownloadButtonContainer isOpen />

      <Spacer h="20px" />

      <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
        Clique no <BText>botão acima</BText> e abra o aplicativo FGTS.
      </chakra.p>
    </>
  );
}
