import { BrowserRouter, Route, Switch } from "react-router-dom";
import { StepRoute } from "../components";

import {
  AllowBankTutorial,
  AllowBankInit,
  AllowWithdrawInit,
  AllowWithdrawTutorial,
  OutOfDate,
  DadosBancariosStep,
  DadosPessoaisStep,
  EnderecoStep,
  FinalFeedback,
  IdentificacaoStep,
  IdentificacaoEditStep,
  OutOfService,
  SimulacaoStep,
  Simular,
  Success,
  SummaryStep,
  ResumeStep,
  LoadingStep,
  DefaultError,
  NoBalance,
  TooManyTries,
  NotFound,
  InProcessBmg,
  InProcessC6,
  InProcessFacta,
  InProcessSafra,
} from ".";
import { ContactUsTemp } from "./feedbacks/ContactUsTemp";

export function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <StepRoute
          num={1}
          exact
          path="/"
          render={() => <IdentificacaoStep />}
        />

        <StepRoute
          num={1}
          path="/identificacao"
          render={() => <IdentificacaoEditStep />}
        />

        <Route exact path="/simular/:token" render={() => <LoadingStep />} />

        <StepRoute num={2} path="/simulacao" render={() => <SimulacaoStep />} />

        <StepRoute
          num={3}
          path="/dadospessoais"
          render={() => <DadosPessoaisStep />}
        />

        <StepRoute num={4} path="/endereco" render={() => <EnderecoStep />} />

        <StepRoute
          num={5}
          path="/dadosbancarios"
          render={() => <DadosBancariosStep />}
        />

        <StepRoute num={6} path="/resumo" render={() => <SummaryStep />} />

        <StepRoute
          num={109}
          path="/feedback"
          render={() => <FinalFeedback />}
        />

        <StepRoute num={110} path="/sucesso" render={() => <Success />} />

        <StepRoute
          num={141}
          path="/fora-de-servico"
          render={() => <OutOfService />}
        />

        <StepRoute
          num={142}
          path="/data-indisponivel"
          render={() => <OutOfDate />}
        />

        <Route
          exact
          path="/como-optar-saque"
          render={() => <AllowWithdrawInit />}
        />

        <Route
          exact
          path="/como-optar-saque/passos"
          render={() => <AllowWithdrawTutorial />}
        />

        <Route
          exact
          path="/entraremos-em-contato"
          render={() => <TooManyTries />}
        />

        <Route exact path="/contato" render={() => <ContactUsTemp />} />

        <Route exact path="/autorizar-banco" render={() => <AllowBankInit />} />

        <Route
          exact
          path="/autorizar-banco/passos"
          render={() => <AllowBankTutorial />}
        />

        <Route path="/simule" render={() => <Simular />} />

        <Route path="/continuar" render={() => <ResumeStep />} />

        <Route
          exact
          path="/proposta-em-andamento/bmg"
          render={() => <InProcessBmg />}
        />
        <Route
          exact
          path="/proposta-em-andamento/c6"
          render={() => <InProcessC6 />}
        />
        <Route
          exact
          path="/proposta-em-andamento/facta"
          render={() => <InProcessFacta />}
        />
        <Route
          exact
          path="/proposta-em-andamento/safra"
          render={() => <InProcessSafra />}
        />

        <Route path="/outra-oportunidade" render={() => <NoBalance />} />

        <Route path="/erro" render={() => <DefaultError />} />

        <Route path="*" render={() => <NotFound />} />
      </Switch>
    </BrowserRouter>
  );
}
