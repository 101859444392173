import { BannerLayout, Contact } from "../../components";
import { chakra, Flex } from "@chakra-ui/react";
import { ReactComponent as ImgNotFound } from "../../assets/img-not-found.svg";
import { Button, Spacer } from "@eqg/chakra-lib.all";

import { useData } from "../../stores";
import { useIsMobile } from "../../utils";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";

export function NotFound() {
  const data = useData();
  const history = useHistory();
  const isMobile = useIsMobile();

  const handleNextClick = useCallback(() => {
    history.push("/" + data.encodeData);
  }, [data, history]);

  return (
    <BannerLayout
      containerProps={{
        alignItems: "center",
        padding: "0px 24px ",
        paddingTop: isMobile ? "24px" : "64px",
      }}
      onlyLogo={true}
    >
      <Flex
        alignItems="center"
        flexDir="column"
        h="100%"
        justifyContent="center"
        maxW="306px"
        w="100%"
      >
        <ImgNotFound style={{ height: "126px", width: "126px" }} />

        <Spacer h="16px" />

        <chakra.h1
          color="primary.500"
          fontSize="1.5rem"
          textAlign="center"
          textStyle="title"
          lineHeight="1.95rem"
        >
          Não encontramos a página que está procurando
        </chakra.h1>

        <Spacer h="12px" />

        <chakra.p color="darkgray.500" textAlign="center" textStyle="p">
          Verifique o endereço digitado ou volte para a o início.
        </chakra.p>

        <Spacer h="24px" />
        <Button borderRadius="24px" onClick={handleNextClick} w="100%">
          Voltar para o início
        </Button>
        <Spacer h="32px" />

        <Contact />
      </Flex>
    </BannerLayout>
  );
}
