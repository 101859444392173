import { chakra, Flex } from "@chakra-ui/react";
import { Spacer } from "@eqg/chakra-lib.components.all";
import { StepIcon } from "./StepIcon";

export type TStepState = "completed" | "current" | "next";

export interface StepProps {
  name: string;
  num: number;
  state: TStepState;
  icon: React.ReactElement;
}

export function Step({ icon, name, num, state }: StepProps) {
  return (
    <Flex>
      <StepIcon icon={icon} state={state} />
      <Spacer w="1rem" />
      <Flex flexDir="column">
        <chakra.h6
          fontFamily="primary"
          lineHeight="1.5rem"
          fontSize="1rem"
          fontWeight="semibold"
          color={state === "next" ? "#ffffffbb" : "white"}
          textShadow="0px 1px 4px #00253D29"
        >
          Passo {num}
        </chakra.h6>
        <chakra.h4
          fontFamily="primary"
          fontSize="1.125rem"
          fontWeight="bold"
          color={state === "next" ? "#ffffffbb" : "white"}
          textShadow="0px 1px 4px #00253D29;"
        >
          {name}
        </chakra.h4>
      </Flex>
    </Flex>
  );
}
